import Navbar from "./NavBar";

const TermsOfService = () => {
  return (
    <div>
      {/* <Navbar /> */}
      <div style={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
        <h1>Terms and conditions of Service</h1>

        <section>
          <h2>1. Introduction</h2>
          <p>
            Welcome to Eightal. By accessing our application, you agree to
            comply with and be bound by these terms and conditions.
          </p>
        </section>

        <section>
          <h2>2. User Accounts</h2>
          <p>
            To use our app, you may need to register an account. Your account
            must be kept secure.
          </p>
          <ol>
            <li>
              2.1 You agree to provide accurate information upon registration.
            </li>
            <li>
              2.2 You are responsible for maintaining the security of your
              account and password.
            </li>
            <li>
              2.3 Notify us immediately of any unauthorized use of your account.
            </li>
          </ol>
        </section>

        <section>
          <h2>3. Content and Conduct</h2>
          <ol>
            <li>
              3.1 You are solely responsible for the content you submit on our
              app.
            </li>
            <li>
              3.2 Do not engage in any unlawful or harmful activities on our
              platform.
            </li>
            <li>
              3.3 We reserve the right to remove any content that violates these
              terms.
            </li>
          </ol>
        </section>

        <section>
          <h2>4. Intellectual Property</h2>
          <p>
            All content provided by us on the platform, including text,
            graphics, and logos, is owned by or licensed to us.
          </p>
        </section>

        <section>
          <h2>5. Limitation of Liability</h2>
          <p>
            We are not liable for any damages arising from your use of our app.
          </p>
        </section>

        <section>
          <h2>6. Changes to Terms</h2>
          <p>
            We reserve the right to modify these terms at any time. Please
            review regularly to stay informed of any changes.
          </p>
        </section>

        <section>
          <h2>7. Contact Us</h2>
          <p>
            If you have any questions about these terms, please contact us at
            [update later].
          </p>
        </section>
      </div>
    </div>
  );
};

export default TermsOfService;
