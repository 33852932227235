import React, { useState } from "react";
import axios from "axios";
import styles from "../css/resetPasswordPopup.module.css";
import config from "./config";

function ResetPasswordPopup({ isOpen, onClose }) {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");

  const userName = sessionStorage.getItem("userName");
  const token = sessionStorage.getItem("token");
  const apiUrl = config.apiUrl;

  const handleResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      setMessage("Passwords do not match.");
      return;
    }
    try {
      await axios.post(
        `${apiUrl}/api/v1/users/changePassword`,
        {
          userName,
          currentPassword,
          newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMessage("Password has been reset successfully.");
      onClose(); // Close popup on success
      clearPopup();
    } catch (error) {
      const errorMessage = error.response?.data || "Error resetting password.";
      setMessage(errorMessage);
    }
  };

  const handleCancleClick = () => {
    clearPopup();
    onClose();
  };

  const clearPopup = () => {
    setMessage("");
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <h2 className={styles.modalTitle}>Reset Password</h2>
        <input
          type="password"
          placeholder="Current Password"
          className={styles.inputField}
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />
        <input
          type="password"
          placeholder="New Password"
          className={styles.inputField}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <input
          type="password"
          placeholder="Confirm New Password"
          className={styles.inputField}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <button
          className={`${styles.button} ${styles.buttonSubmit}`}
          onClick={handleResetPassword}
          disabled={!currentPassword || !newPassword || !confirmPassword}
        >
          Submit
        </button>
        <button
          className={`${styles.button} ${styles.buttonCancel}`}
          onClick={handleCancleClick}
        >
          Cancel
        </button>
        {message && <p className={styles.message}>{message}</p>}
      </div>
    </div>
  );
}

export default ResetPasswordPopup;
