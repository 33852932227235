import "./App.css";
import "./css/general.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import Signup from "./common/Signup";
import Home from "./Home";
import Footer from "./common/Footer";
import LoginCard from "./common/LoginCard";
import HomePublic from "./common/HomePublic";
import Plan from "./tracking/Plan";
import Welcome from "./tracking/Welcome";
import Application from "./tracking/Application";
import Settings from "./common/Settings";
import ApplicationAnalytics from "./tracking/ApplicationAnalytics";
import CheckoutPage from "./common/CheckoutPage";
import PaymentSuccessPage from "./common/PaymentSuccessPage";
import PlanToPay from "./tracking/PlansToPay";
import AppContent from "./AppContent";
import ScrollToTop from "./common/ScrollToTop";

function App() {
  return (
    <div>
      <Router>
        {/* <Routes>
          <Route path="/" element={<HomePublic />} />
          <Route path="signup" element={<Signup />} />
          <Route path="/home" element={<Home />} />
          <Route path="/login" element={<LoginCard />} />
          <Route path="/plans" element={<Plan />} />
          <Route path="/paidplans" element={<PlanToPay />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/application" element={<Application />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/analytics" element={<ApplicationAnalytics />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/payment" element={<PaymentSuccessPage />} />
        </Routes>
        {location.pathname !== "signup" && <Footer />} */}
        <ScrollToTop />
        <AppContent />
      </Router>
    </div>
  );
}

export default App;
