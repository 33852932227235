import { useNavigate } from "react-router-dom";
import style from "../css/plan.module.css";
import Signup from "../common/Signup";
import { useState } from "react";

const PlanToPay = () => {
  const nav = useNavigate();
  const handlePlanSelect = (planName, planId, planPrice) => {
    if (planName && planId) {
      nav("/checkout", {
        state: { selectedPlan: planName, selectedPlanId: planId, selectedPlanPrice: planPrice },
      });
    } else {
      alert("Please select a plan to continue");
    }
  };
  const handleChoose = () => {
    nav("/signup");
  };
  return (
    <div className={style.pricingRoot}>
      <div className={style.pricingSection}>
        <h2 className={style.pricingTitle}>Choose Your Plan</h2>

        <div className={style.pricingPlans}>
          {/* Free Plan */}
          <div className={style.plan}>
            <h3 className={style.planTitle}>Free</h3>
            <p className={style.planPrice}>
              $0<span>/month</span>
            </p>
            <ul className={style.planFeatures}>
              <li>Basic Features</li>
              <li>Track 2 Applications</li>
              <li>Access to Analytics</li>
              <li>Community Support</li>
            </ul>
            <button className={style.planButton} disabled={true}>
              Get Started
            </button>
          </div>

          {/* Standard Plan */}
          <div className={`${style.plan} ${style.planPopular}`}>
            <h3 className={style.planTitle}>Standard</h3>
            <p className={style.planPrice}>
              $9.99<span>/month</span>
            </p>
            <ul className={style.planFeatures}>
              <li>All Free Features</li>
              <li>Priority Support</li>
              <li>Unlimited Tracking</li>
              {/* <li>Custom Branding</li> */}
            </ul>
            <button
              className={style.planButton}
              onClick={() => {
                handlePlanSelect("Standard Plan", 2,9.99);
              }}
            >
              Choose Standard
            </button>
          </div>

          {/* Professional Plan */}
          <div className={style.plan}>
            <h3 className={style.planTitle}>Professional</h3>
            <p className={style.planPrice}>
              $2.99<span>/week</span>
            </p>
            <ul className={style.planFeatures}>
              <li>All Standard Features</li>
              <li>Dedicated Support</li>
              <li>Unlimited Projects</li>
              <li>Advanced Analytics</li>
            </ul>
            <button
              className={style.planButton}
              onClick={() => {
                handlePlanSelect("Professional Plan", 3, 2.99);
              }}
            >
              Choose Professional
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanToPay;
