import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import style from "../css/welcome.module.css";
import axios from "axios";
import config from "../common/config";

const Welcome = () => {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const nav = useNavigate();
  const query = useQuery();
  const step = query.get("step") || "welcome";
  const [firstName, SetFirstName] = useState("");
  const [area, setArea] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [source, SetSource] = useState("");
  const [strategy, SetStrategy] = useState("none");
  const welcomeStatus = sessionStorage.getItem("isWelcomeComplete");
  const userName = sessionStorage.getItem("userName");
  const token = sessionStorage.getItem("token");

  useEffect(() => {
    //     const [response1, response2] = await Promise.all([
    //   axios.get("/api/endpoint1"),
    //   axios.get("/api/endpoint2"),
    // ]);
    const fetchData = async () => {
      try {
        const [response] = await Promise.all([
          axios.get(`${apiUrl}/api/v1/common/supportedCountries`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        ]);
        //console.log(response.data);
        setCountryList(response.data.$values);
      } catch (error) {
        console.log(error);
      }
    };

    // console.log("welcomeStatus", { welcomeStatus });
    if (welcomeStatus === "true") {
      nav("/home"); // Navigate to home page if welcome is complete
    }
    fetchData();
  }, [nav]);

  const apiUrl = config.apiUrl;
  const startup = () => {
    const updateWelcomeStatus = async () => {
      await axios
        .put(
          `${apiUrl}/api/v1/users/updateWelcomeStatus`,
          { userName: userName, isWelcomeComplete: true },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          //console.log("User signed up successfully:", response.data);
          const data = response.data;
          sessionStorage.setItem("isWelcomeComplete", true);
          nav("/home");
        })
        .catch((error) => {
          sessionStorage.setItem("isWelcomeComplete", false);
          alert("Something went wrong, please try again later");
        });
    };

    const welcomeComplete = async () => {
      await axios
        .post(
          `${apiUrl}/api/v1/common/welcomeComplete`,
          {
            userName: userName,
            firstName: firstName,
            source: source,
            country: area,
            strategy: strategy,
            isWelcomeComplete: true,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          sessionStorage.setItem("isWelcomeComplete", true);
          nav("/home");
        })
        .catch((error) => {
          sessionStorage.setItem("isWelcomeComplete", false);
          alert("Something went wrong, please try again later");
        });
    };
    if (token) {
      welcomeComplete();
      //updateWelcomeStatus();
    } else {
      nav("/login");
    }
  };

  // Function to handle moving to the next step
  const goToNextStep = () => {
    switch (step) {
      case "welcome":
        nav("/welcome?step=source");
        break;
      case "source":
        nav("/welcome?step=name");
        break;
      case "name":
        //nav("/welcome?step=strategy");
        nav("/welcome?step=country");
        break;
      case "strategy":
        nav("/welcome?step=area");
        break;
      case "country":
        nav("/welcome?step=summary");
        break;
      default:
        break;
    }
  };

  // Function to render different steps
  const renderStepContent = () => {
    switch (step) {
      case "welcome":
        return (
          <div className={style.questionBox}>
            <h2>Welcome, Let's start with a few simple questions</h2>
            <button className={style.btnContinue} onClick={goToNextStep}>
              Continue
            </button>
          </div>
        );
      case "source":
        return (
          <div className={style.questionBox}>
            <h2>How did you hear about Eightal?</h2>
            <select
              value={source}
              onChange={(e) => SetSource(e.target.value)}
              className={style.strategyDropdown}
            >
              <option value="" disabled>
                Select a source
              </option>
              <option value="friends">Friends</option>
              <option value="tiktok">Tik Tok</option>
              <option value="youtube">Youtube</option>
              <option value="red">Red</option>
              <option value="others">Others</option>
            </select>
            <button
              className={style.btnContinue}
              onClick={goToNextStep}
              disabled={!source.trim()}
            >
              Continue
            </button>
          </div>
        );
      case "name":
        return (
          <div className={style.questionBox}>
            <h2>What's your name?</h2>
            <input
              type="text"
              placeholder="Enter your first name"
              value={firstName}
              onChange={(e) => SetFirstName(e.target.value)}
            />
            <button
              className={style.btnContinue}
              onClick={goToNextStep}
              disabled={!firstName.trim()}
            >
              Continue
            </button>
          </div>
        );
      case "country":
        return (
          <div className={style.questionBox}>
            <h2>Where are you searching jobs?</h2>
            <select
              value={area} // Binds the selected value
              onChange={(e) => setArea(e.target.value)} // Updates state when the user selects an area
              className={style.strategyDropdown} // Add a className for styling
            >
              <option value="" disabled>
                Select
              </option>
              {/* Populate dropdown with the list from the API */}
              {countryList.length > 0 ? (
                countryList.map((countryItem, index) => (
                  <option key={index} value={countryItem}>
                    {countryItem}
                  </option>
                ))
              ) : (
                <option value="UK">United Kindom</option>
              )}
            </select>

            <button
              className={style.btnContinue}
              onClick={goToNextStep}
              disabled={!area.trim()}
            >
              Continue
            </button>
            <div className={style.countrySelectionInfo}>
              *More countries will be supported later. The location information
              is only for checking the status of companies' sponsorship. Other
              functions are not affected by the location.
            </div>
          </div>
        );
      case "strategy":
        return (
          <div className={style.questionBox}>
            <h2>What's your strategy?</h2>
            <select
              value={strategy}
              onChange={(e) => SetStrategy(e.target.value)}
              className={style.strategyDropdown}
            >
              <option value="" disabled>
                Select a strategy
              </option>
              <option value="Outnumbering">Outnumbering</option>
              <option value="finegrain">Fine-grain</option>
            </select>
            <button
              className={style.btnContinue}
              onClick={goToNextStep}
              disabled={!strategy.trim()}
            >
              Continue
            </button>
          </div>
        );
      case "summary":
        return (
          <div className={style.summaryBox}>
            <p className={style.summaryText}>Great! You are all set now!</p>
            <button className={style.btnContinue} onClick={startup}>
              Start
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  return <div>{renderStepContent()}</div>;
};

export default Welcome;
