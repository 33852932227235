import { useEffect, useState } from "react";
import style from "../css/loginCard.module.css";
import { Link, useNavigate } from "react-router-dom";
import config from "./config";
import { jwtDecode } from "jwt-decode";

const LoginCard = (onLogin) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userRole, setUserRole] = useState("");

  const nav = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    console.log("login page loading");
    if (token) {
      const decodedToken = jwtDecode(token); // Decode the token to get expiry date
      const currentTime = Date.now() / 1000; // Convert to seconds

      // Check if token is expired
      if (decodedToken.exp < currentTime) {
        // Token is expired, remove it
        console.log("token expired");
        sessionStorage.removeItem("token");
      } else {
        // Token is valid, user is authenticated
        nav("/home");
      }
    }
  }, []);
  const handleSubmit = async (event) => {
    event.preventDefault();
    // Here is the authentication logic
    const apiUrl = config.apiUrl;
    if (email != "" && password != "") {
      try {
        const response = await fetch(
          `${apiUrl}/api/v1/authentication/authenticate`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email, password }),
          }
        );
        if (response.ok) {
          const data = await response.json();
          const token = data.token;
          console.log("Login successful");
          sessionStorage.setItem("token", token);
          sessionStorage.setItem("userName", data.userName);
          sessionStorage.setItem("isWelcomeComplete", data.isWelcomeComplete);
          sessionStorage.setItem("planId", data.planId);
          console.log(data.isWelcomeComplete);
          if (data.isWelcomeComplete) {
            nav("/home");
          } else {
            nav("/welcome");
          }
          // onLogin();
          // Handle successful login here (e.g., redirect to another page)
        } else {
          console.error("Login failed");
          alert("Invalid credentials");
          // Handle failed login here (e.g., display error message)
        }
      } catch (error) {
        console.error("Error:", error);
        // Handle network errors here
      }
    } else {
      alert("Plese fill out username or password.");
    }
  };
  return (
    <div className={style.loginRoot}>
      <div className={style.loginCard}>
        <h1 className={style.loginTitle}>Login</h1>
        <form onSubmit={handleSubmit}>
          <input
            className={style.loginInput}
            type="text"
            name="u"
            placeholder="Email"
            required="required"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            className={style.loginInput}
            type="password"
            name="p"
            placeholder="Password"
            required="required"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            type="submit"
            className={`${style.btn} ${style.btnPrimary} ${style.btnBlock} ${style.btnLarge}`}
          >
            Log in
          </button>
        </form>
        <div className={style.signupBox}>
          <label className={style.loginLabel}>
            Don't have an account yet ?
          </label>
          <Link className={style.btnSignup} to="/signup">
            Sign Up
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LoginCard;
