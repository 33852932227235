const Privacy = () => {
  return (
    <div>
      All data is held and processed by Eightal in accordance with the General
      Data Protection Regulation and will be not be disclosed to any third
      parties. Personal data is never sold to a third party. For more
      information about how your personal data is stored and used, please visit
      our <a>privacy</a>privacy page.
    </div>
  );
};

export default Privacy;
