import { toPascalCaseWithSpace } from "../common/Utility";
import styles from "../css/compareApp.module.css";

const CompareApplication = ({ selectedApps }) => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Compare Applications</h2>
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.fieldColumn}> - </th>
              {selectedApps.map((app, index) => (
                <th key={index} className={styles.appColumn}>
                  {app.jobTitle}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={styles.field}>Status</td>
              {selectedApps.map((app, index) => (
                <td key={index} className={styles.dataCell}>
                  {toPascalCaseWithSpace(app.status)}
                </td>
              ))}
            </tr>
            <tr>
              <td className={styles.field}>Company</td>
              {selectedApps.map((app, index) => (
                <td key={index} className={styles.dataCell}>
                  {toPascalCaseWithSpace(app.companyName)}
                </td>
              ))}
            </tr>
            <tr>
              <td className={styles.field}>Salary (K)</td>
              {selectedApps.map((app, index) => (
                <td key={index} className={styles.dataCell}>
                  {app.averageSalary}
                </td>
              ))}
            </tr>
            <tr>
              <td className={styles.field}>Work Location</td>
              {selectedApps.map((app, index) => (
                <td key={index} className={styles.dataCell}>
                  {app.location}
                </td>
              ))}
            </tr>
            <tr>
              <td className={styles.field}>Interview Date</td>
              {selectedApps.map((app, index) => (
                <td key={index} className={styles.dataCell}>
                  {app.interviewDate === "0001-01-01T00:00:00"
                    ? "N/A"
                    : new Date(app.interviewDate).toLocaleDateString()}
                </td>
              ))}
            </tr>
            <tr>
              <td className={styles.field}>Notes</td>
              {selectedApps.map((app, index) => (
                <td key={index} className={styles.dataCell}>
                  <div className={styles.notesContainer}>
                    {app.notes === "" ? "N/A" : app.notes}
                  </div>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CompareApplication;
