// NotFound.js
import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "../css/notFound.module.css";

const NotFound = () => {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate("/home");
  };

  return (
    <div className={styles.notFoundContainer}>
      <h1 className={styles.title}>404 - Page Not Found</h1>
      <p className={styles.message}>
        Sorry, the page you are looking for does not exist.
      </p>
      <button className={styles.homeButton} onClick={goToHome}>
        Go to Home
      </button>
    </div>
  );
};

export default NotFound;
