import { useEffect, useState } from "react";
import style from "../src/css/home.module.css";
import { useNavigate } from "react-router-dom";
import config from "./common/config";
import axios from "axios";
import Loading from "./common/Loading";
import NavBar from "../src/common/NavBar";
import ApplicationList from "./tracking/ApplicationList";

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalRecords, setTotalRecords] = useState(130);
  const nav = useNavigate();
  const token = sessionStorage.getItem("token");
  const userName = sessionStorage.getItem("userName");
  const planId = sessionStorage.getItem("planId");
  const isWelcomeComplete = sessionStorage.getItem("isWelcomeComplete");
  const apiUrl = config.apiUrl;
  const applicationsTest = [
    {
      jobTitle: "Frontend Developer",
      companyName: "Tech Solutions",
      salary: 80000,
      status: "Applied",
    },
    {
      jobTitle: "Backend Engineer",
      companyName: "Innovatech",
      salary: 90000,
      status: "Interviewing",
    },
    {
      jobTitle: "Full Stack Developer",
      companyName: "DevCorp",
      salary: 100000,
      status: "Offer",
    },
  ];

  // Sample data
  const sampleRecords = [
    {
      id: 1,
      jobTitle: "Software Engineer",
      company: "Google",
      createdAt: "2024-10-01",
      salary: "$120,000",
      status: "applied",
      hasIcon: true,
    },
    {
      id: 2,
      jobTitle: "Product Manager",
      company:
        "FacebookFacebookFacebookFacebookFacebookFacebookFacebookFacebookFacebookFacebookFacebookFacebookFacebook",
      createdAt: "2024-09-15",
      salary: "$150,000",
      status: "INTErviewed",
      hasIcon: true,
    },
    {
      id: 3,
      jobTitle: "Data Scientist",
      company: "AppleAppleAppleAppleAppleAppleApple",
      createdAt: "2024-10-10",
      salary: "$135,000",
      status: "Offer",
    },
    {
      id: 5,
      jobTitle: "Data Scientist",
      company: "Apple",
      createdAt: "2024-10-10",
      salary: "$135,000",
      status: "offer",
      hasIcon: false,
    },
    {
      id: 4,
      jobTitle: "Data Scientist",
      company: "Apple",
      createdAt: "2024-10-10",
      salary: "$135,000",
      status: "offer",
      hasIcon: true,
    },
    {
      id: 6,
      jobTitle: "Data Scientist",
      company: "Apple",
      createdAt: "2024-10-10",
      salary: "$135,000",
      status: "offer",
      hasIcon: false,
    },
  ];

  const [applications, setApplications] = useState([]);
  const [appStatusFilter, setAppStatusFilter] = useState([]);

  useEffect(() => {
    setPageNumber(1);
    handleRefresh();
  }, [pageSize]);

  useEffect(() => {
    console.log("loading home");
    console.log("from home welcome", isWelcomeComplete);
    if (token) {
      if (isWelcomeComplete === "false") {
        console.log("from home welcome", isWelcomeComplete);
        nav("/welcome");
      }

      const fetchAllApplications = async () => {
        setLoading(true);
        try {
          const response = await axios.get(
            `${apiUrl}/api/v1/applications/userName/${userName}/${pageNumber}/${pageSize}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log("applications", response.data);
          //console.log("userName", userName);
          setApplications(response.data.applications.$values);
          setTotalRecords(response.data.totalRecords);
          console.log("applications", response.data.$values);
        } catch (error) {
          //redirect back to home page?
          console.log("error", error);
        } finally {
          setLoading(false);
        }
      };

      fetchApplicationStatusFilter();
      fetchAllApplications();
    } else {
      nav("/");
    }
  }, [refresh, pageNumber]);

  const fetchApplicationStatusFilter = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/v1/filter/${userName}/status`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("----", response.data.$values);
      setAppStatusFilter(response.data.$values);
    } catch (error) {
      console.log("error", error);
    } finally {
      // setLoading(false);
    }
  };

  const handleRefresh = () => {
    //setLoading(true);
    setRefresh((prev) => !prev);
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  return (
    <div className={style.root}>
      <NavBar />
      {/* <h1 className={style.greeting}>The applications</h1> */}
      <div>
        <ApplicationList
          applications={applications}
          statusFilter={appStatusFilter}
          onRefresh={handleRefresh}
        />
        {/* <ApplicationList applications={applications} /> */}
        <div className={style.paginationContainer}>
          <button
            className={style.paginationButton}
            disabled={pageNumber === 1}
            onClick={() => setPageNumber(pageNumber - 1)}
          >
            Previous
          </button>

          <span className={style.pageInfo}>
            Page {pageNumber} of {Math.ceil(totalRecords / pageSize)}
          </span>

          <button
            className={style.paginationButton}
            disabled={pageNumber * pageSize >= totalRecords}
            onClick={() => setPageNumber(pageNumber + 1)}
          >
            Next
          </button>

          {/* Page Size Selector */}
          <select
            className={style.pageSizeSelect}
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default Home;
