import React from "react";
import styles from "../css/jdPreviewForPopup.module.css";

const JDPreviewForPopup = ({ content, onClose, onDownload }) => (
  <div className={styles.overlay}>
    <div
      className={styles.content}
      dangerouslySetInnerHTML={{ __html: content }} // Shows HTML content
    ></div>
    <div className={styles.buttonsContainer}>
      <button onClick={onClose} className={styles.closeButton}>
        Close
      </button>
      <button onClick={onDownload} className={styles.downloadButton}>
        Download
      </button>
    </div>
  </div>
);

export default JDPreviewForPopup;
