import { useNavigate } from "react-router-dom";
import style from "../css/plan.module.css";
import Signup from "../common/Signup";

const Plan = () => {
  const nav = useNavigate();
  const handleChoose = () => {
    nav("/signup");
  };
  return (
    <div className={style.pricingRoot}>
      <div className={style.pricingSection}>
        <h2 className={style.pricingTitle}>Choose Your Plan</h2>

        <div className={style.pricingPlans}>
          {/* Free Plan */}
          <div className={style.plan}>
            <h3 className={style.planTitle}>Free</h3>
            <p className={style.planPrice}>
              $0<span>/month</span>
            </p>
            <ul className={style.planFeatures}>
              <li>Track limited applications</li>
              <li>Access to Analytics</li>
              <li>Keywords highlighting</li>
              <li>10MB of free file storage</li>
              <li>Free resume builder</li>
              <li>Free cover letter builder</li>
              <li>Resume and cover letter management</li>
              <li>Ad-free platform</li>
            </ul>
            <button
              className={style.planButton}
              onClick={() => {
                handleChoose();
              }}
            >
              Get Started
            </button>
          </div>

          {/* Standard Plan */}
          {/* <div className={`${style.plan} ${style.planPopular}`}>
            <h3 className={style.planTitle}>Standard</h3>
            <p className={style.planPrice}>
              $9.99<span>/month</span>
            </p>
            <ul className={style.planFeatures}>
              <li>All Free Features</li>
              <li>Priority Support</li>
              <li>Unlimited Tracking</li>
            </ul>
            <button
              className={style.planButton}
              onClick={() => {
                handleChoose();
              }}
            >
              Choose Standard
            </button>
          </div> */}

          {/* Professional Plan */}
          {/* <div className={style.plan}>
            <h3 className={style.planTitle}>Professional</h3>
            <p className={style.planPrice}>
              $0.59<span>/application</span>
            </p>
            <ul className={style.planFeatures}>
              <li>All Standard Features</li>
              <li>Dedicated Support</li>
              <li>Unlimited Projects</li>
              <li>Advanced Analytics</li>
            </ul>
            <button className={style.planButton} onClick={handleChoose}>
              Choose Professional
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Plan;
