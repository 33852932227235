import React, { useState } from "react";
import style from "../css/editorNavbar.module.css"; // Import the CSS module
import logo from "../icons/target-goals-icon.png";
import cancelIcon from "../icons/cancel-icon.png";
import saveIcon from "../icons/confirm-icon.png";
import editIcon from "../icons/edit-round-icon.png";
import downloadIcon from "../icons/download-cloud-color-icon.png";
import downloadRound from "../icons/download-file-round-icon.png";
import mg from "../icons/magnifying-glass-icon.png";
import previewIcon from "../icons/search-magnifying-glass-icon.png";
import { useNavigate } from "react-router-dom";

const EditorNavbar = ({
  showPreview,
  onCancelClick,
  onSaveClick,
  onEditorClick,
  onPreviewClick,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const nav = useNavigate();
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleSaveClick = () => {
    onSaveClick();
    nav("?step=cover-letter");
  };

  const handleCancelClick = () => {
    onCancelClick();
    nav("?step=cover-letter");
  };

  const handleLogout = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("userName");
    sessionStorage.removeItem("isWelcomeComplete");
    nav("/");
  };

  return (
    <nav className={style.navbar}>
      <div className={style.logoContainer} onClick={handleCancelClick}>
        <img
          src={cancelIcon} // Replace with your logo
          alt="cancel"
          className={style.logo}
        />
      </div>
      <div className={style.logoContainer} onClick={handleSaveClick}>
        <img
          src={saveIcon} // Replace with your logo
          alt="save"
          className={style.logo}
        />
      </div>
      <div className={style.logoContainer} onClick={toggleMenu}>
        <img src={downloadRound} alt="download" className={style.logo} />
      </div>
      {/* <div className={style.logoContainer} onClick={toggleMenu}>
        <img
          src={downloadIcon} // Replace with your logo
          alt="download"
          className={style.logo}
        />
      </div> */}
      {showPreview ? (
        <div
          className={`${style.logoContainer} ${style.editorPreviewIcon}`}
          onClick={onEditorClick}
        >
          <img
            src={editIcon} // Replace with your logo
            alt="editor icon"
            className={style.logo}
          />
        </div>
      ) : (
        <div
          className={`${style.logoContainer} ${style.editorPreviewIcon}`}
          onClick={onPreviewClick}
        >
          <img
            src={previewIcon} // Replace with your logo
            alt="preview icon"
            className={style.logo}
          />
        </div>
      )}
    </nav>
  );
};

export default EditorNavbar;
