import React, { useState } from "react";
import style from "../css/infoPopup.module.css";

function InfoPopup({ iconText, message, onClose }) {
  return (
    <div className={style.overlay} onClick={onClose}>
      <div className={style.popup} onClick={(e) => e.stopPropagation()}>
        <span className={style.icon}>{iconText}</span>
        {/* <h2 className={style.title}>Success!</h2> */}
        <h2 className={style.message}>{message || "Operation successful!"}</h2>
        <button className={style.closeButton} onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
}

export default InfoPopup;
