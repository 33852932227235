import axios from "axios";
import { useState } from "react";
import style from "../css/signup.module.css";
import logo from "../icons/goal-icon.png";
import googleIcon from "../icons/google-color-icon.png";
import linkedInIcon from "../icons/linkedin-app-icon.png";
import config from "./config";
import { useNavigate } from "react-router-dom";
import { clearSessionStorageInfo } from "./Utility";

const Signup = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    firstName: "firstName",
    lastName: "lastName",
  });

  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const apiUrl = config.apiUrl;
  const token = sessionStorage.getItem("token");
  const nav = useNavigate();
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    clearSessionStorageInfo();
    // Post request to backend API
    axios
      .post(`${apiUrl}/api/v1/users/signup`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("User signed up successfully:", response.data);
        const data = response.data;
        sessionStorage.setItem("token", data.token);
        sessionStorage.setItem("userName", data.userName);
        sessionStorage.setItem("isWelcomeComplete", false);
        sessionStorage.setItem("planId", data.planId);
        // Handle successful signup
        nav("/welcome");
      })
      .catch((error) => {
        console.error("There was an error signing up!", error);
        if (error.response) {
          setError(error.response.data.message);
        } else {
          setError("Server error");
        }
      });
  };

  return (
    <div>
      <nav className={style.navbar}>
        <div className={style.navContainer}>
          <div className={style.navLeft}>
            <a href="/">
              <img className={style.logo} alt="logo" src={logo} />
            </a>
          </div>
          <div className={style.navLinks}>
            <a href="/login" className={style.signInLink}>
              Log In
            </a>
          </div>
        </div>
      </nav>

      <div className={style.signupRoot}>
        <div>
          <h1 className={style.signupTitle}>Sign Up</h1>
        </div>

        {error && <div className={style.error}>{error}</div>}
        <form onSubmit={handleSubmit} className={style.signupForm}>
          <div>
            <label htmlFor="email">Email address</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label htmlFor="password">Password</label>
            <div className={style.passwordContainer}>
              <input
                type={showPassword ? "text" : "password"}
                className={style.passwordInput}
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
              <button
                type="button"
                className={style.showPasswordBtn}
                onClick={togglePasswordVisibility}
              >
                {showPassword ? "Hide" : "Show"}
              </button>
            </div>
          </div>
          {/* todo: modifeid checked and formdata */}
          <div className={style.termsSection}>
            <label htmlFor="terms" className={style.termsLabel}>
              By creating an account, you agree to our{" "}
              <a href="/terms" target="_blank">
                Terms of Service
              </a>{" "}
              and{" "}
              <a href="/privacy" target="_blank">
                Privacy Policy
              </a>
              .
            </label>
          </div>
          <button type="submit" className={style.btn}>
            Create Account
          </button>
          <div className={style.divider}>
            <span className={style.dividerText}>or</span>
          </div>
          <div className={style.socialSignupContainer}>
            <div className={style.socialButton}>
              <img className={style.socialIcon} src={googleIcon} />
              Sign Up with Google
            </div>

            <div className={style.socialButton}>
              <img className={style.socialIcon} src={linkedInIcon} />
              Sign Up with LinkedIn
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Signup;
