import React, { useEffect, useRef, useState } from "react";
import style from "../css/applicationList.module.css";
import ApplicationDetailPopup from "./ApplicationDetailPopup";
import StatusDropdown from "./StatusDropDown";
import ApplicationListToolbar from "./ApplicationListToolbar";
import { useNavigate } from "react-router-dom";
import greenIcon from "../icons/green-checkmark-icon.png";
import trashIcon from "../icons/red-trash-can-icon.png";
import arrorIcon from "../icons/chevron-direction-bottom-round-outline-icon.png";
import compareIcon from "../icons/compare-icon.png";
import addIcon from "../icons/add-round-green-icon.png";
import axios from "axios";
import config from "../common/config";
import AppDetailPopup from "./AppDetailPopup";
import { toPascalCaseWithSpace } from "../common/Utility";
import InfoPopup from "../common/InfoPopup";
import ConfirmPopup from "../common/ConfirmPopup";
import Popup from "../common/Popup";
import InterviewEdit from "./InterviewEdit";
import CompareApplication from "./CompareApplication";

const ApplicationList = ({ applications, statusFilter, onRefresh }) => {
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [updatedApplication, setUpdatedApplication] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState(statusFilter);
  const [selectedOptionColors, setSelectedOptionColors] = useState([]);
  const [selectedApps, setSelectedApps] = useState([]);
  const [showComparePopup, setShowComparePopup] = useState(false);
  const [sortOption, setSortOption] = useState("submittedDate");
  const [showBanner, setShowBanner] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [infoPopupText, setInfoPopupText] = useState("");
  const [showInterviewEdit, setShowInterviewEdit] = useState(false);

  const [statusAnimation, setStatusAnimation] = useState(null);
  const [showDeleteConfirmationPopup, setShowDeleteConfirmationPopup] =
    useState(false);
  const [showTrashBin, setShowTrashBin] = useState(false);
  const [showCompanyInfoDropdown, setShowCompanyInfoDropdown] = useState();
  const companyContainerRef = useRef(null);
  const [companyInfoId, setCompanyInfoId] = useState(null);
  const [currentApplications, setCurrentApplications] = useState(applications);
  const [toolbarBtnLeft, setToolbarBtnLeft] = useState("Archived Applications");
  const [toolbarBtnRight, setToolbarBtnRight] = useState(
    "Wishlisted Applications"
  );
  const [applicationStatus, setApplicationStatus] = useState({
    id: "",
    status: "",
  });
  const token = sessionStorage.getItem("token");
  const userName = sessionStorage.getItem("userName");
  const apiUrl = config.apiUrl;
  const nav = useNavigate();
  const iconRef = useRef(null);

  // Define the custom order for statuses
  const statusOrder = [
    "applied",
    "interviewed",
    "offer",
    "declined",
    "wishListed",
    "archived",
  ];

  useEffect(() => {
    if (selectedApplication) {
      document.body.style.overflow = "hidden";
      console.log("application list=== hidden");
    } else {
      document.body.style.overflow = "";
    }
    // Cleanup when the component unmounts
    return () => {
      document.body.style.overflow = "";
    };
  }, [selectedApplication]);

  //console.log("app list is refreshed", applications);
  // Close popup when clicking outside
  // useEffect(() => {
  //   // if (
  //   //   toolbarBtnLeft === "Archived Applications" &&
  //   //   toolbarBtnRight === "Wishlisted Applications"
  //   // ) {
  //   //   const activeApp = applications.filter(
  //   //     (a) => a.status != "archived" && a.status != "wishListed"
  //   //   );
  //   //   setCurrentApplications(activeApp);
  //   // } else if (toolbarBtnLeft === "Active Applications") {
  //   //   const filtered = applications.filter((app) => app.status === "archived");
  //   //   setCurrentApplications(filtered);
  //   // } else if (toolbarBtnRight === "Active Applications") {
  //   //   const filtered = applications.filter(
  //   //     (app) => app.status === "wishListed"
  //   //   );
  //   //   setCurrentApplications(filtered);
  //   // }

  //   const statusColors = {
  //     Applied: "#3598db", // Red
  //     Wishlisted: "#f39c12", // Orange
  //     Offer: "#2ecc71", // Green
  //     Interviewed: "#8e44ad", // Purple
  //     Declined: "#e74c3c",
  //     Archived: "#6c757d",
  //   };

  //   // Convert selected options into an array of colors
  //   const selectedColors = selectedOptions.map(
  //     (option) => statusColors[option]
  //   );
  //   console.log(selectedColors);
  //   setSelectedOptionColors(selectedColors);
  //   setCurrentApplications(applications);

  //   if (sortOption === "submittedDate") {
  //     //setCurrentApplications(orderBySubmittedDate(applications));
  //   } else if (sortOption === "status") {
  //     // Step 1: Group applications by status
  //     const groupedApplications = applications.reduce((groups, application) => {
  //       const { status } = application;
  //       if (!groups[status]) {
  //         groups[status] = [];
  //       }
  //       groups[status].push(application);
  //       return groups;
  //     }, {});
  //     // Step 2: Sort each group by submittedDate in descending order
  //     Object.keys(groupedApplications).forEach((status) => {
  //       groupedApplications[status].sort(
  //         (a, b) => new Date(b.submittedDate) - new Date(a.submittedDate)
  //       );
  //     });
  //     // Optional: Flatten the result back into an array if needed
  //     const orderedGroupedApplications =
  //       Object.values(groupedApplications).flat();
  //     //setCurrentApplications(orderedGroupedApplications);
  //     setCurrentApplications(groupAndOrderByStatusAndDate(applications));
  //   }

  //   const handleClickOutside = (event) => {
  //     if (iconRef.current && !iconRef.current.contains(event.target)) {
  //       setShowBanner(false);
  //     }
  //   };
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [
  //   applications,
  //   applicationStatus,
  //   iconRef,
  //   toolbarBtnLeft,
  //   toolbarBtnRight,
  //   sortOption,
  // ]);

  const handleJobTitleClick = ({ application }) => {
    handleClosePopup(application);
  };

  const handleOpenPopup = (application) => {
    setSelectedApplication(application);
  };

  const handleClosePopup = () => {
    setSelectedApplication(null);
  };

  const handleApplicationStatusUpdate = async (applicationId, newStatus) => {
    try {
      await axios.put(
        `${apiUrl}/api/v1/applications/updateApplicationStatus`,
        { userName, applicationId, newStatus },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (newStatus === "interview") {
        setShowInterviewEdit(true);
        setStatusAnimation(newStatus);
      }
      if (newStatus === "offer" || newStatus === "declined") {
        setStatusAnimation(newStatus);
        // Automatically hide the animation after 3 seconds (or the animation duration)
        setTimeout(() => {
          setStatusAnimation(null);
        }, 3000); // Adjust the duration to match your animation time
      }
    } catch (error) {
      console.error("Error updating :", error);
      setInfoPopupText("Sorry, Cannot Go Back");
      setShowInfoPopup(true);
    }
  };

  const handleStatusChange = async (application, newStatus) => {
    setUpdatedApplication(application);

    await handleApplicationStatusUpdate(application.applicationId, newStatus);
    setApplicationStatus((prev) => ({
      ...prev, // Spread the previous state to retain other properties
      id: application.applicationId, // Update the id
      status: newStatus, // Update the status
    }));
  };

  // Function to handle New Application button click
  const handleNewAppClick = () => {
    console.log("New Application clicked");
    nav("/application");
  };

  const handleCompanyInfoIconClick = (id) => {
    setCompanyInfoId(id);
    setShowCompanyInfoDropdown(true);
  };

  const handleTrashIconClick = async () => {
    setShowDeleteConfirmationPopup(true);
    // const applicationsIdsToDelete = currentApplications.map(
    //   (app) => app.applicationId
    // );
    // const query = applicationsIdsToDelete.map((id) => `ids=${id}`).join("&");
    // try {
    //   await axios
    //     .delete(`${apiUrl}/api/v1/applications?${query}`, {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //         "Content-Type": "application/json",
    //       },
    //       // data: { ids: applicationsIdsToDelete },
    //     })
    //     .then((response) => {
    //       onRefresh();
    //     });
    // } catch (error) {
    //   setShowPopup(true);
    // }
  };

  const handleDeleteConfirmPopupConfirmClick = async () => {
    const applicationsIdsToDelete = selectedApps.map(
      (app) => app.applicationId
    );
    const query = applicationsIdsToDelete.map((id) => `ids=${id}`).join("&");
    try {
      await axios
        .delete(`${apiUrl}/api/v1/applications?${query}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          // data: { ids: applicationsIdsToDelete },
        })
        .then((response) => {
          onRefresh();
        });
    } catch (error) {
      setShowPopup(true);
    }
  };

  const handleDeleteConfirmPopupCloseClick = () => {
    setShowDeleteConfirmationPopup(false);
  };

  //
  const [dropdownVisible, setDropdownVisible] = React.useState(false);
  //const [selectedOptions, setSelectedOptions] = useState([]);
  const options = [
    "Wishlisted",
    "Applied",
    "Interview",
    "Offer",
    "Declined",
    "Archived",
  ];
  const toggleOption = (option) => {
    const isSelected = selectedOptions.includes(option);
    const updatedOptions = isSelected
      ? selectedOptions.filter((o) => o !== option) // Remove if already selected
      : [...selectedOptions, option]; // Add if not selected
    if (selectedOptions.includes(option)) {
      // If already selected, remove it
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      // Otherwise, add it
      setSelectedOptions([...selectedOptions, option]);
    }
    saveSelectedStatuesToDB(updatedOptions);
  };
  // Save selected statuses to backend
  const saveSelectedStatuesToDB = async (update) => {
    console.log("-----save to db");
    console.log();
    try {
      await axios.post(`${apiUrl}/api/v1/filter/${userName}/status`, update, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      console.error("Error saving filters:", error);
    }
  };

  // Function to update an application in the state
  const updateApplication = (updatedApp) => {
    console.log("updated appid", updatedApp.appli);
    setCurrentApplications((prevApps) =>
      prevApps.map((app) =>
        app.applicationId === updatedApp.applicationId ? updatedApp : app
      )
    );
    // Also update the selected application in the popup
    setSelectedApplication(updatedApp);
  };

  //don't update selected application
  const updateApplication2 = (updatedApp) => {
    console.log("updated appid", updatedApp.appli);
    setCurrentApplications((prevApps) =>
      prevApps.map((app) =>
        app.applicationId === updatedApp.applicationId ? updatedApp : app
      )
    );
  };

  // Function to handle checkbox selection
  const handleSelect = (app) => {
    setSelectedApps((prev) => {
      if (prev.includes(app)) {
        return prev.filter(
          (selected) => selected.applicationId !== app.applicationId
        ); // Deselect
      } else {
        return [...prev, app]; // Add to selection (max 3)
      }
    });
  };

  // Function to open the comparison popup
  const openComparePopup = () => {
    if (selectedApps.length >= 2) {
      setShowComparePopup(true);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        companyContainerRef.current &&
        !companyContainerRef.current.contains(event.target)
      ) {
        setShowCompanyInfoDropdown(false); // Hide the paragraph if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  //util funcs
  function toPascalCase(str) {
    return str
      .split(" ") // Split the string by spaces (or you could use another delimiter)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter, lowercase the rest
      .join(""); // Join them back together without spaces
  }

  //debug section
  //console.log("interview popup ", showInterviewEdit);

  return (
    <div className={style.applicationsContainer}>
      <h2 className={style.title}>Your Job Applications</h2>
      {selectedApplication ? (
        <ApplicationDetailPopup
          application={selectedApplication}
          onClose={handleClosePopup}
          isOpen={selectedApplication != null}
          onUpdate={updateApplication}
        />
      ) : (
        ""
      )}
      {showDeleteConfirmationPopup && (
        <ConfirmPopup
          message={"Are you sure to delete them all?"}
          confirmText={"Delete"}
          onConfirm={handleDeleteConfirmPopupConfirmClick}
          onClose={handleDeleteConfirmPopupCloseClick}
        />
      )}

      <div className={style.toolbarIconContainer}>
        <img
          src={addIcon}
          alt="add"
          className={style.addIcon}
          onClick={handleNewAppClick}
        />
        <img
          src={trashIcon}
          alt="add"
          className={style.addIcon}
          onClick={handleTrashIconClick}
        />
      </div>

      <div className={style.tableWrapper}>
        <table className={style.recordsTable}>
          <thead>
            <tr>
              <th className={style.selectorHeader}>
                <span></span>
              </th>
              <th>Job Title</th>
              <th>Company</th>

              <th>Salary</th>
              <th>Submission Day</th>
              <th className={style.statusHeader}>
                <div className={style.headerWithMultiSelect}>
                  <span className={style.statusHeaderText}>Status</span>
                  {/* <button
                    className={style.multiSelectToggle}
                    onClick={() => setDropdownVisible(!dropdownVisible)}
                  >
                    ▼
                  </button> */}
                  <img
                    alt="down arrow"
                    src={arrorIcon}
                    className={style.dropDownIcon}
                    onClick={() => {
                      if (dropdownVisible) {
                        onRefresh();
                      }
                      setDropdownVisible(!dropdownVisible);
                    }}
                  />

                  {dropdownVisible && (
                    <div className={style.multiSelectDropdown}>
                      {options.map((option) => (
                        <div
                          key={option}
                          className={`${style.option} ${
                            selectedOptions.includes(option)
                              ? style[option.toLowerCase()]
                              : ""
                          }`}
                          onClick={() => toggleOption(option)}
                        >
                          {option}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {currentApplications.map((record) => (
              <tr key={record.applicationId}>
                <td>
                  <input
                    className={style.appCheckbox}
                    type="checkbox"
                    checked={selectedApps.includes(record)}
                    onChange={() => handleSelect(record)}
                  />
                </td>
                <td
                  className={style.jobTitle}
                  onClick={() => {
                    handleOpenPopup(record);
                  }}
                >
                  {toPascalCaseWithSpace(record.jobTitle)}
                </td>
                <td>
                  <div className={style.companyContainer}>
                    <div></div>
                    <span>{toPascalCaseWithSpace(record.companyName)}</span>

                    {record.hasSponsorship && (
                      <div
                        ref={companyContainerRef}
                        className={style.companyIconContainer}
                      >
                        <img
                          src={greenIcon}
                          className={style.companyIcon}
                          onClick={() =>
                            handleCompanyInfoIconClick(record.applicationId)
                          }
                        />
                        {showCompanyInfoDropdown &&
                          record.applicationId === companyInfoId && (
                            <div className={style.companyInfoDropdown}>
                              The company provides sponsorship
                            </div>
                          )}
                      </div>
                    )}
                  </div>
                </td>
                <td>
                  {record.salaryLowerLimit === 0 &&
                  record.salaryUpperLimit === 0
                    ? "N/A"
                    : record.salaryLowerLimit > 0 && record.salaryUpperLimit > 0
                    ? `${record.salaryLowerLimit} - ${record.salaryUpperLimit}K`
                    : record.salaryLowerLimit > 0
                    ? `${record.salaryLowerLimit}K`
                    : `${record.salaryUpperLimit}K`}
                </td>
                <td>{new Date(record.submittedAt).toLocaleDateString()}</td>
                <td>
                  <StatusDropdown
                    currentApplication={record}
                    currentStatus={record.status}
                    onChange={(newStatus) =>
                      handleStatusChange(record, newStatus)
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {statusAnimation === "offer" && (
        <div className={style.congratsAnimation}>
          🎉 Congratulations on the Offer! 🎉
        </div>
      )}
      {statusAnimation === "declined" && (
        <div className={style.motivationAnimation}>
          💪 Don't give up! The right opportunity is waiting for you. 💪
        </div>
      )}
      {/* <Popup
        children={
          <div className={style.interviewedEditPopup}>
            <h2 className={style.interviewedEditPopupTitle}>
              Interview Details
            </h2>
            <p className={style.interviewedEditPopupDescription}>
              Update your salary range and add notes for the interview.
            </p>
            <div className={style.formGroup}>
              <label htmlFor="salaryRange" className={style.label}>
                Salary Range
              </label>
              <input
                type="text"
                id="salaryRange"
                className={style.input}
                placeholder="e.g., $50,000 - $70,000"
              />
            </div>
            <div className={style.formGroup}>
              <label htmlFor="notes" className={style.label}>
                Notes
              </label>
              <textarea
                id="notes"
                className={style.textarea}
                placeholder="Add any important notes..."
              />
            </div>
            <div className={style.actions}>
              <button onClick={null} className={style.cancelButton}>
                Cancel
              </button>
              <button onClick={null} className={style.saveButton}>
                Save
              </button>
            </div>
          </div>
        }
        isOpen={showInterviewEdit}
        onClose={() => setShowInterviewEdit(false)}
      /> */}
      {updatedApplication && (
        <Popup
          children={
            <InterviewEdit
              applicationId={updatedApplication.applicationId}
              oldNotes={updatedApplication.notes}
              oldInterviewDate={updatedApplication.interviewDate}
              onClose={() => {
                setShowInterviewEdit(false);
              }}
              onUpdate={updateApplication2}
            />
          }
          isOpen={showInterviewEdit}
          onClose={() => {
            setShowInterviewEdit(false);
          }}
        />
      )}
      {selectedApps.length >= 2 && selectedApps.length <= 3 && (
        <button className={style.compareButton} onClick={openComparePopup}>
          <img
            alt="compare icon"
            src={compareIcon}
            className={style.compareIcon}
          />
        </button>
      )}
      <Popup
        children={<CompareApplication selectedApps={selectedApps} />}
        isOpen={showComparePopup}
        onClose={() => {
          setSelectedApps([]);
          setShowComparePopup(false);
        }}
      />

      {showInfoPopup && (
        <InfoPopup
          iconText={"☹️"}
          message={infoPopupText}
          onClose={() => {
            setShowInfoPopup(false);
          }}
        />
      )}
      {showPopup ? (
        <InfoPopup
          iconText={"🙁"}
          message={"No archived applications to delete."}
          onClose={() => {
            setShowPopup(false);
            setShowDeleteConfirmationPopup(false);
          }}
        />
      ) : (
        ""
      )}
      {currentApplications.length == 0 && (
        <div className={style.emptyWarningText}>
          <p>
            You don't have any applications under this category, please trying
            changing the filter or start a new application.
          </p>
        </div>
      )}
      {showTrashBin && sortOption !== "status" ? (
        <div className={style.trashSection}>
          <img
            className={style.trashBin}
            alt="trash bin"
            src={trashIcon}
            onClick={handleTrashIconClick}
          />{" "}
          <p>Delete all Archived</p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ApplicationList;
