import React, { useState } from "react";
import style from "../css/test.module.css";
import StatusDropdown from "../tracking/StatusDropDown";
const Test = () => {
  // Sample data
  const sampleRecords = [
    {
      id: 1,
      jobTitle: "Software Engineer",
      company: "Google",
      createdAt: "2024-10-01",
      salary: "$120,000",
      status: "Applied",
    },
    {
      id: 2,
      jobTitle: "Product Manager",
      company: "Facebook",
      createdAt: "2024-09-15",
      salary: "$150,000",
      status: "Interview",
    },
    {
      id: 3,
      jobTitle: "Data Scientist",
      company: "Apple",
      createdAt: "2024-10-10",
      salary: "$135,000",
      status: "Offer",
    },
  ];

  const handleStatusChange = (id, newStatus) => {
    console.log(`Record ID: ${id}, New Status: ${newStatus}`);
    // Logic to update the status in the database
  };
  return (
    <div className={style.homeContainer}>
      <div className={style.topRow}>
        <input
          type="text"
          placeholder="Search..."
          className={style.searchBox}
        />
        <select className={style.filterDropdown}>
          <option value="">Filter by Created Time</option>
          <option value="today">Today</option>
          <option value="week">This Week</option>
          <option value="month">This Month</option>
        </select>
        <select className={style.filterDropdown}>
          <option value="">Filter by Status</option>
          <option value="wishlisted">Wishlisted</option>
          <option value="applied">Applied</option>
          <option value="interview">Interview</option>
          <option value="offer">Offer</option>
          <option value="declined">Declined</option>
        </select>
        <button className={style.newAppButton}>Create New Application</button>
      </div>

      {/* <div className={style.recordsTable}>
        <div className={style.tableHeader}>
          <span>Job Title</span>
          <span>Company</span>
          <span>Created At</span>
          <span>Salary</span>
          <span>Status</span>
        </div>
        {sampleRecords.map((record) => (
          <div className={style.tableRow} key={record.id}>
            <span>{record.jobTitle}</span>
            <span>{record.company}</span>
            <span>{record.createdAt}</span>
            <span>{record.salary}</span>
            <span>{record.status}</span>
          </div>
        ))}
      </div> */}
      <div className={style.tableContainer}>
        <table className={style.recordsTable}>
          <thead>
            <tr>
              <th>Job Title</th>
              <th>Company</th>
              <th>Created At</th>
              <th>Salary</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {sampleRecords.map((record) => (
              <tr key={record.id}>
                <td>{record.jobTitle}</td>
                <td>{record.company}</td>
                <td>{record.createdAt}</td>
                <td>{record.salary}</td>
                <td>
                  <StatusDropdown
                    currentStatus={record.status}
                    onChange={(newStatus) =>
                      handleStatusChange(record.id, newStatus)
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Test;
