import { useState } from "react";
import style from "../css/feedback.module.css";
import axios from "axios";
import Navbar from "./NavBar";
import config from "./config";
import { useNavigate } from "react-router-dom";
import InfoPopup from "./InfoPopup";

function Feedback() {
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const nav = useNavigate();

  const token = sessionStorage.getItem("token");
  const userName = sessionStorage.getItem("userName");
  const apiUrl = config.apiUrl;

  const handleRating = (index) => {
    setRating(index + 1); // Ratings are 1-based
    if (!canSubmit) {
      setCanSubmit(true);
    }
  };

  const submitFeedback = async () => {
    try {
      const response = await axios.post(
        `${apiUrl}/api/v1/common/feedback`,
        {
          userName,
          rating,
          feedbackContent: feedback,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShowPopup(true);
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  //console.log("refresh feedback");
  return (
    <div>
      <Navbar />
      {showPopup && (
        <InfoPopup
          message="Thank you for your feedback!"
          onClose={() => {
            setShowPopup(false);
            nav("/home");
          }}
        />
      )}
      <div className={style.feedbackPage}>
        <h2 className={style.title}>We Value Your Feedback</h2>
        <p className={style.subtitle}>
          Help us improve by sharing your thoughts.
        </p>

        <div className={style.ratingSection}>
          <p className={style.question}>How would you rate your experience?</p>
          <div className={style.stars}>
            {[...Array(5)].map((_, index) => (
              <span
                key={index}
                className={`${style.star} ${
                  index < rating ? style.filledStar : style.outlinedStar
                }`}
                onClick={() => handleRating(index)}
              >
                {index < rating ? "★" : "☆"}
              </span>
            ))}
          </div>
        </div>

        <textarea
          placeholder="Tell us what you liked or how we can improve..."
          className={style.textArea}
          value={feedback}
          rows={6}
          onChange={(e) => setFeedback(e.target.value)}
        ></textarea>

        <button
          className={style.submitBtn}
          onClick={submitFeedback}
          disabled={!canSubmit}
        >
          Submit Feedback
        </button>
      </div>
    </div>
  );
}

export default Feedback;
