import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import Signup from "./common/Signup";
import Home from "./Home";
import Footer from "./common/Footer";
import LoginCard from "./common/LoginCard";
import HomePublic from "./common/HomePublic";
import Plan from "./tracking/Plan";
import Welcome from "./tracking/Welcome";
import Application from "./tracking/Application";
import Settings from "./common/Settings";
import ApplicationAnalytics from "./tracking/ApplicationAnalytics";
import CheckoutPage from "./common/CheckoutPage";
import PaymentSuccessPage from "./common/PaymentSuccessPage";
import PlanToPay from "./tracking/PlansToPay";
import Login from "./common/Login";
import Test from "./common/Test";
import AboutUs from "./common/AboutUs";
import ResumeEditor from "./tracking/ResumeEditor";
import CoverLetterEditor from "./tracking/CoverLetterEditor";
import TermsOfService from "./common/TermsOfService";
import Privacy from "./common/Privacy";
import Feedback from "./common/Feedback";
import NotFound from "./common/NotFound";
import FileUpload from "./common/FileUpload";

const AppContent = () => {
  const location = useLocation();
  return (
    <div>
      <Routes>
        <Route path="/" element={<HomePublic />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/home" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/plans" element={<Plan />} />
        <Route path="/paidplans" element={<PlanToPay />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/application" element={<Application />} />
        <Route path="/resume" element={<ResumeEditor />} />
        <Route path="/cover-letter" element={<CoverLetterEditor />} />
        <Route path="/test" element={<Test />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/analytics" element={<ApplicationAnalytics />} />
        <Route path="/checkout" element={<CheckoutPage />} />
        <Route path="/payment" element={<PaymentSuccessPage />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/test" element={<FileUpload />} />
        <Route path="/fileupload" element={<FileUpload />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {location.pathname !== "/signup" &&
        location.pathname !== "/login" &&
        location.pathname !== "/resume" &&
        location.pathname !== "/application" && <Footer />}
    </div>
  );
};

export default AppContent;
