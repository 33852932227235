import React, { useEffect, useState } from "react";
import style from "../css/applicationListToolbar.module.css";

function ApplicationListToolbar({
  onArchiveClick,
  onWishlistClick,
  onNewAppClick,
  onSortChange,
  toolbarBtnLeft,
  toolbarBtnRight,
}) {
  const [leftName, setLeftName] = useState("buttonArchive");
  const [rightName, setRightName] = useState("buttonWishlist");

  useEffect(() => {
    if (toolbarBtnLeft === "Archived Applications") {
      setLeftName("buttonArchive");
    } else {
      setLeftName("buttonActive");
    }

    if (toolbarBtnRight === "Wishlisted Applications") {
      setRightName("buttonWishlist");
    } else {
      setRightName("buttonActive");
    }
  }, [toolbarBtnLeft, toolbarBtnRight]);

  return (
    <div className={style.buttonBarContainer}>
      {/* Left group: Archived and Wishlisted */}
      <div className={style.leftGroup}>
        <button
          className={`${style.button} ${style[leftName]} `}
          onClick={onArchiveClick}
        >
          {/* Archived Applications */}
          {toolbarBtnLeft}
        </button>
        <button
          className={`${style.button} ${style[rightName]} `}
          onClick={onWishlistClick}
        >
          {/* Wishlisted Jobs */}
          {toolbarBtnRight}
        </button>
      </div>

      {/* Right group: Sort dropdown and New Application */}
      <div className={style.rightGroup}>
        <div className={style.dropdownContainer}>
          <select
            className={style.dropdown}
            onChange={(e) => onSortChange(e.target.value)}
          >
            <option value="submittedDate">Order by Submitted Date</option>
            <option value="status">Order by Status</option>
          </select>
        </div>
        <button className={style.newAppButton} onClick={onNewAppClick}>
          New Application
        </button>
      </div>
    </div>
  );
}

export default ApplicationListToolbar;
