import React, { useEffect } from "react";
import style from "../css/applicationDetailPopup.module.css";
import DOMPurify from "dompurify";
import { toPascalCase, toPascalCaseWithSpace } from "../common/Utility";
import AppDetailPopup from "./AppDetailPopup";

const ApplicationDetailPopup = ({ application, onClose, onUpdate, isOpen }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen]);

  if (!application) return null;
  if (!isOpen) return null;

  // console.log(
  //   "application",
  //   application,
  //   application.coverLetter,
  //   application.resume
  // );
  // submittedDate={new Date(application.submittedAt).toLocaleDateString()}

  //debug
  console.log("app detail popup -- application", application);

  const sanitizedHTML = DOMPurify.sanitize(application.jobDescription);
  return (
    <div className={style.modalOverlay} onClick={onClose}>
      <div className={style.modalContent} onClick={(e) => e.stopPropagation()}>
        <AppDetailPopup
          applicationId={application.applicationId}
          jobTitle={application.jobTitle}
          company={application.companyName}
          location={application.location}
          submittedDate={application.submittedAt}
          interviewDate={application.interviewDate}
          salaryMin={application.salaryLowerLimit}
          salaryMax={application.salaryUpperLimit}
          status={application.status}
          description={sanitizedHTML}
          resumeName={application.resume ? application.resume.name : undefined}
          coverLetter={application.coverLetter}
          notes={application.notes}
          onUpdate={onUpdate}
        />
      </div>
    </div>
  );
};

export default ApplicationDetailPopup;
