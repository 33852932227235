import React from "react";
import style from "../css/confirmPopup.module.css";

const ConfirmPopup = ({ message, confirmText, onConfirm, onClose }) => {
  return (
    <div className={style.popupOverlay}>
      <div className={style.popupBox}>
        <h2>Alert</h2>
        <p>{message}</p>
        <div className={style.btnGroup}>
          <button className={style.btnConfirm} onClick={() => onConfirm(true)}>
            {confirmText}
          </button>
          <button className={style.btnClose} onClick={() => onClose(false)}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPopup;
