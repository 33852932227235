import React, { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import axios from "axios"; // Assuming you're using axios for API calls
import config from "../common/config";
import style from "../css/applicationAnalytics.module.css";
import NavBar from "../common/NavBar";

const ApplicationAnalytics = () => {
  const [chartData, setChartData] = useState(null);

  const [lineChartData, setLineChartData] = useState(null);

  const userName = sessionStorage.getItem("userName");
  const token = sessionStorage.getItem("token");
  const apiUrl = config.apiUrl;

  const mixedChartData = {
    labels: ["Week 1", "Week 2", "Week 3", "Week 4"], // X-axis labels (Weeks)
    datasets: [
      {
        label: "Wishlisted",
        data: [5, 3, 4, 2], // Wishlisted applications data
        backgroundColor: "#03a9f4", // Bar color for wishlisted
      },
      {
        label: "Applied",
        data: [10, 8, 6, 12], // Applied applications data
        backgroundColor: "#ff9800", // Bar color for applied
      },
      {
        label: "Interview",
        data: [1, 2, 3, 4], // Interview applications data
        backgroundColor: "#2196f3", // Bar color for interview
      },
      {
        label: "Offer",
        data: [0, 1, 1, 2], // Offer applications data
        backgroundColor: "#4caf50", // Bar color for offers
      },
      {
        label: "Declined",
        data: [2, 1, 0, 1], // Declined applications data
        backgroundColor: "#f44336", // Bar color for declined
      },
      {
        type: "line", // Line chart for User's Applications
        label: "User's Applications",
        data: [18, 15, 14, 21], // Total user applications per week
        fill: false,
        borderColor: "#8e44ad", // Line color for user's applications
        borderWidth: 2,
        tension: 0.4, // Smooth curve
      },
      {
        type: "line", // Line chart for User's Applications
        label: "Average number of Applications",
        data: [20, 13, 12, 30], // Total user applications per week
        fill: false,
        borderColor: "#4caf50", // Line color for user's applications
        borderWidth: 2,
        tension: 0.4, // Smooth curve
      },
    ],
  };

  useEffect(() => {
    const data = [
      {
        week: "09/16-09/23",
        wishlisted: 4,
        applied: 5,
        interview: 1,
        offer: 0,
        declined: 0,
      },
      {
        week: "2024-09-23",
        wishlisted: 2,
        applied: 7,
        interview: 2,
        offer: 1,
        declined: 0,
      },
      {
        week: "2024-09-30",
        wishlisted: 1,
        applied: 8,
        interview: 3,
        offer: 1,
        declined: 1,
      },
      // {
      //   week: "2024-10-07",
      //   wishlisted: 3,
      //   applied: 6,
      //   interview: 2,
      //   offer: 0,
      //   declined: 2,
      // },
    ];
    const labels = data.map((item) => item.week);
    const wishlistedData = data.map((item) => item.wishlisted);
    const appliedData = data.map((item) => item.applied);
    const interviewData = data.map((item) => item.interview);
    const offerData = data.map((item) => item.offer);
    const declinedData = data.map((item) => item.declined);

    setChartData({
      labels,
      datasets: [
        {
          label: "Wishlisted",
          data: wishlistedData,
          backgroundColor: "#03a9f4",
        },
        {
          label: "Applied",
          data: appliedData,
          backgroundColor: "#ff9800",
        },
        {
          label: "Interview",
          data: interviewData,
          backgroundColor: "#2196f3",
        },
        {
          label: "Offer",
          data: offerData,
          backgroundColor: "#4caf50",
        },
        {
          label: "Declined",
          data: declinedData,
          backgroundColor: "#f44336",
        },
      ],
    });

    axios
      .get(`${apiUrl}/api/v1/applications/analytics/${userName}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const data = response.data;
        //console.log("data", data);
        const formattedData = {
          labels: data.labels.$values, // Labels for the x-axis
          datasets: [
            {
              label: "Your Applications",
              data: data.appsFiveWeekCount.$values,
              borderColor: "rgba(75,192,192,1)",
              backgroundColor: "rgba(75,192,192,0.2)",
              tension: 0.4, // Smoothing
            },
            {
              label: "Average Applications",
              data: data.averageAppsFiveWeekCount.$values,
              borderColor: "rgba(153,102,255,1)",
              backgroundColor: "rgba(153,102,255,0.2)",
              tension: 0.4,
            },
          ],
        };

        setLineChartData(formattedData);
      })
      .catch((error) => {
        //console.error("There was an error loading data", error);
      });
  }, []);

  return (
    <div>
      <NavBar />
      <div className={style.analyticsPage}>
        <div className={style.analyticsContainer}>
          <h2 className={style.pageTitle}>Application Analytics</h2>

          {/* First Chart Section */}
          <div className={style.chartSection}>
            {/* <h3 className={style.chartTitle}>Applications per Week</h3> */}
            {lineChartData ? (
              <Line
                data={lineChartData}
                options={{
                  responsive: true,
                  aspectRatio: 1,
                  plugins: {
                    legend: {
                      position: "top",
                    },
                    title: {
                      display: true,
                      text: "Applications / week",
                    },
                  },
                  scales: {
                    x: {
                      title: {
                        display: true,
                        text: "Weeks",
                      },
                    },
                    y: {
                      beginAtZero: true,
                      title: {
                        display: true,
                        text: "Number of Applications",
                      },
                    },
                  },
                }}
              />
            ) : (
              <p className={style.loadingText}>Loading data...</p>
            )}
          </div>

          {/* First Chart Section */}
          {/* <div className={style.chartSection}>
            <h3 className={style.chartTitle}>Applications per Week</h3>
            {chartData ? (
              <Bar
                className={style.barChart}
                data={chartData}
                options={{
                  responsive: true,
                  // maintainAspectRatio: true,
                  // aspectRatio: 1,
                  plugins: {
                    legend: {
                      position: "top",
                    },
                    title: {
                      display: true,
                      text: "Applications",
                    },
                  },
                }}
              />
            ) : (
              <p className={style.loadingText}>Loading data...</p>
            )}
          </div> */}

          {/* Additional chart sections can be added similarly */}
          {/* Mixed Chart Section */}
          {/* <div className={style.chartSection}>
            <h3 className={style.chartTitle}>
              Applications per Week (Mixed Bar & Line)
            </h3>
            {mixedChartData ? (
              <Bar
                data={mixedChartData}
                options={{
                  responsive: true,
                  aspectRatio: 0.7,
                  plugins: {
                    legend: {
                      position: "top",
                    },
                    title: {
                      display: true,
                      text: "Applications Status vs User's Applications",
                    },
                  },
                  scales: {
                    x: {
                      title: {
                        display: true,
                        text: "Weeks",
                      },
                    },
                    y: {
                      beginAtZero: true, // Ensure the y-axis starts at 0
                      title: {
                        display: true,
                        text: "Number of Applications",
                      },
                    },
                  },
                }}
              />
            ) : (
              <p className={style.loadingText}>Loading data...</p>
            )}
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ApplicationAnalytics;
