import style from "../css/loading.module.css";
import logo from "../icons/target-goals-icon.png";

const Loading = () => {
  return (
    <div className={style.loadingContainer}>
      <div className={style.logoContainer}>
        <img src={logo} alt="Logo" className={style.logo} />
      </div>
      <div className={style.spinner}></div>
    </div>
  );
};

export default Loading;
