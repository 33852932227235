import style from "../css/footer.module.css";
import logo from "../icons/goal-icon.png";
import ytbLogo from "../icons/youtube-color-icon.png";
import tiktok from "../icons/tiktok-square-color-icon.png";

const Footer = () => {
  return (
    <footer className={style.footer}>
      <div className={style.footerContainer}>
        {/* Logo Section */}
        <div className={style.footerLogo}>
          <a href="/">
            <img src={logo} alt="Company Logo" className={style.logo} />
          </a>
        </div>

        {/* Links Section */}
        <div className={style.footerLinks}>
          <a href="/aboutus" className={style.footerLink}>
            About Us
          </a>
          <a href="/contact" className={style.footerLink}>
            Contact
          </a>
          <a href="/privacy" className={style.footerLink}>
            Privacy Policy
          </a>
          <a href="/terms" className={style.footerLink}>
            Terms of Service
          </a>
        </div>

        {/* Social Media Section */}
        <div className={style.footerSocial}>
          <a href="https://youtube.com" className={style.socialIcon}>
            <img
              src={ytbLogo}
              className={style.socialMediaLogo}
              alt="youtube logo"
            />
          </a>
          <a href="https://tiktok.com" className={style.socialIcon}>
            <img className={style.tiktokLogo} src={tiktok} alt="tiktok logo" />
          </a>
        </div>
      </div>

      {/* Copyright Section */}
      <div className={style.footerBottom}>
        <p>&copy; {new Date().getFullYear()} Eightal. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
