import React, { useEffect, useState } from "react";
import styles from "../css/checkoutPage.module.css"; // Import CSS module
import { useLocation } from "react-router-dom";
import logo from "../icons/target-goals-icon.png";
import Navbar from "./NavBar";

const CheckoutPage = () => {
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const { selectedPlan, selectedPlanId, selectedPlanPrice } =
    location.state || {};
  console.log("selected plan", selectedPlan);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handlePayment = () => {
    setLoading(true);
    // Handle payment logic
  };

  return (
    <div className={styles.checkoutContainer}>
      <Navbar />
      <header className={styles.checkoutHeader}>
        {/* <h1>Your Company Logo</h1> */}
        {/* <img className={styles.companyLogo} src={logo} /> */}
        <p className={styles.secureCheckout}>🔒 Secure Checkout</p>
      </header>

      <div className={styles.checkoutBody}>
        <section className={styles.planSummary}>
          <h2>Selected Plan</h2>
          <p className={styles.planType}>{selectedPlan}</p>
          <p className={styles.planPrice}>${selectedPlanPrice}</p>
          <ul className={styles.planFeatures}>
            <li>Unlimited access</li>
            <li>Priority support</li>
            <li>Advanced analytics</li>
          </ul>
        </section>

        <section className={styles.billingInformation}>
          <h2>Billing Information</h2>
          <input type="text" placeholder="Cardholder Name" />
          <input type="text" placeholder="Card Number" />
          <input type="text" placeholder="Expiry Date (MM/YY)" />
          <input type="text" placeholder="CVV" />
        </section>

        <section className={styles.orderSummary}>
          <h2>Order Summary</h2>
          <div className={styles.orderRow}>
            <span>Plan Cost</span>
            <span>${selectedPlanPrice}</span>
          </div>
          <div className={styles.orderRow}>
            <span>Taxes</span>
            <span>$4.99</span>
          </div>
          <div className={styles.orderTotal}>
            <span>Total</span>
            <span>$54.98</span>
          </div>
        </section>

        <button
          className={styles.checkoutButton}
          onClick={handlePayment}
          disabled={loading}
        >
          {loading ? "Processing..." : "Complete Payment"}
        </button>
      </div>
    </div>
  );
};

export default CheckoutPage;
