import React, { useEffect, useState } from "react";
import axios from "axios";
import style from "../css/settings.module.css"; // Assuming you're using CSS modules
import config from "./config";
import Navbar from "./NavBar";
import AlertPopup from "./AlertPopup";
import ConfirmPopup from "./ConfirmPopup";
import { useNavigate } from "react-router-dom";
import InfoPopup from "./InfoPopup";
import ResetPasswordPopup from "./ResetPasswordPopup";

const Settings = () => {
  const [showModal, setShowModal] = useState(false);
  const [showSaveSuccess, setShowSaveSuccess] = useState(false);
  const [showSaveFailure, setShowSaveFailure] = useState(false);
  const [email, setEmail] = useState("email");
  const [firstName, setFirstName] = useState("user.firstName");
  const [lastName, setLastName] = useState("user.lastName");
  const [strategy, setStrategy] = useState("");
  const [location, setLocation] = useState("loading failed");
  const [password, setPassword] = useState("");
  const [isAutoRenew, setIsAutoRenew] = useState(false);
  const [isHighestPlan, setIsHighestPlan] = useState(false);
  const apiUrl = config.apiUrl;
  const [actionConfirmed, setActionConfirmed] = useState(null);
  const nav = useNavigate();
  const userName = sessionStorage.getItem("userName");
  const token = sessionStorage.getItem("token");
  const [isResetPopupOpen, setIsResetPopupOpen] = useState(false);

  const openResetPopup = () => setIsResetPopupOpen(true);
  const closeResetPopup = () => setIsResetPopupOpen(false);
  //   setFirstName("test");
  //   setLastName("testLastName");
  //   setStrategy(sessionStorage.getItem("strategy"));
  //   setLocation("uk");

  //   const openModal = () => setShowModal(true);
  //   const closeModal = () => setShowModal(false);
  useEffect(() => {
    const getUserSettings = async () => {
      try {
        // Make the request with the token in the Authorization header
        const response = await axios.get(
          `${apiUrl}/api/v1/users/getSettings/${userName}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Send token in the Authorization header
            },
          }
        );

        const data = response.data;

        setFirstName(data.firstName);
        setLastName(data.lastName);
        setLocation(data.country);
        setEmail(data.email);
        if (data.strategy) {
          setStrategy(data.strategy);
        }

        // Handle the response here
        console.log("User settings:", response.data);
      } catch (error) {
        // Handle errors here

        if (error.response) {
          // Server responded with a status other than 2xx
          console.error("Error response:", error.response);
        } else if (error.request) {
          // Request was made but no response received
          console.error("Error request:", error.request);
        } else {
          // Something happened in setting up the request
          console.error("Error message:", error.message);
        }
      }
    };

    getUserSettings();
  }, [apiUrl, userName, token]); // Dependencies

  const handleUpdate = async () => {
    try {
      const payload = {
        userName,
        firstName,
        lastName,
        country: location,
      };
      await axios
        .put(`${apiUrl}/api/v1/users/updateSettings`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setShowSaveFailure(false);
          setShowSaveSuccess(true);
        })
        .catch((error) => {
          setShowSaveSuccess(false);
          setShowSaveFailure(true);
        });
    } catch (error) {
      setShowSaveSuccess(false);
      setShowSaveFailure(true);
      console.error("Error updating settings:", error);
    }
  };

  const handleResetPassword = async () => {
    openResetPopup();
    // try {
    //   await axios.post(
    //     `${apiUrl}/api/v1/users/resetPassword`,
    //     { password },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     }
    //   );
    //   alert("Password has been reset!");
    // } catch (error) {
    //   console.error("Error resetting password:", error);
    // }
  };

  const handleDeleteAccount = async () => {
    handleShowAlert();
    // if (window.confirm("Are you sure you want to delete your account?")) {
    //   try {
    //     await axios.delete(`${apiUrl}/api/v1/user/deleteAccount`, {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     });
    //     alert("Your account has been deleted.");
    //   } catch (error) {
    //     console.error("Error deleting account:", error);
    //   }
    // }
  };

  const handleUpgradePlan = async () => {
    //show a popup about upgrade plan

    try {
      await axios.post(
        `${apiUrl}/api/v1/user/upgradePlan`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert("You have upgraded your plan!");
    } catch (error) {
      console.error("Error upgrading plan:", error);
    }
  };

  const [showAlert, setShowAlert] = useState(false);

  const handleShowAlert = () => {
    setShowAlert(true);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const handleConfirm = async (result) => {
    if (result) {
      try {
        await axios
          .delete(`${apiUrl}/api/v1/users/deleteAccount/${userName}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            if (response.status == 200) {
              nav("/");
              sessionStorage.removeItem("token");
              sessionStorage.removeItem("userName");
            }
            console.log("response", response);
          })
          .catch((error) => {
            alert("Deleting account failed, please try again later");
            setShowAlert(false);
          });
        //alert("Your account has been deleted.");
        // setShowAlert(false);
        // nav("/");
        //todo clear all the sessionstorage stuff
      } catch (error) {
        console.error("Error deleting account:", error);
      }
      console.log("delete requestio has been sent");
      setActionConfirmed("Action confirmed!");
    } else {
      setActionConfirmed("Action cancelled.");
    }
  };

  const handleSaveSuccess = () => {
    setShowSaveSuccess(false);
  };

  const handleSaveFailure = () => {
    setShowSaveFailure(false);
  };

  return (
    <div>
      <Navbar />
      <div className={style.settingsContainer}>
        {showAlert && (
          <ConfirmPopup
            message="Are you sure you want to delete the account?(Irreversible operation!)"
            confirmText="Confirm"
            onConfirm={handleConfirm}
            onClose={handleCloseAlert}
          />
        )}
        {showSaveSuccess && (
          <InfoPopup
            iconText={"✔"}
            message="Changes have been saved successfully!"
            onClose={handleSaveSuccess}
          />
        )}
        {showSaveFailure && (
          <AlertPopup
            message="Settings save failed"
            onClose={handleSaveFailure}
          />
        )}
        <ResetPasswordPopup
          isOpen={isResetPopupOpen}
          onClose={closeResetPopup}
        />
        <h1>Settings</h1>

        <div className={style.formGroup}>
          <label htmlFor="email">Email Address</label>
          <input
            type="text"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={true}
          />
        </div>
        <div className={style.formGroup}>
          <label htmlFor="firstName">First Name</label>
          <input
            type="text"
            id="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>

        <div className={style.formGroup}>
          <label htmlFor="lastName">Last Name</label>
          <input
            type="text"
            id="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>

        <div className={`${style.formGroup} ${style.strategyPart}`}>
          <label htmlFor="strategy">Job Search Strategy</label>
          <input
            type="text"
            id="strategy"
            value={strategy || ""}
            onChange={(e) => setStrategy(e.target.value)}
            disabled={true}
          />
        </div>

        <div className={style.formGroup}>
          <label htmlFor="location">Location</label>
          <input
            type="text"
            id="location"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            disabled={true}
          />
        </div>

        <div className={style.currentPlanBox}>
          <div className={style.planDetails}>
            <p className={style.planContent}>
              Current Plan: <span className={style.planName}>{"Standard"}</span>
            </p>
            <label className={style.autoRenewLabel}>
              <input
                type="checkbox"
                checked={isAutoRenew}
                onChange={() => setIsAutoRenew(!isAutoRenew)}
                className={style.autoRenewCheckbox}
              />
              Auto-Renew
            </label>
          </div>

          <button
            className={style.planActionBtn}
            onClick={() => {
              nav(isHighestPlan ? "/downgrade" : "/upgrade");
            }}
          >
            {isHighestPlan ? "Downgrade" : "Upgrade"}
          </button>
        </div>

        <div className={style.buttonGroup}>
          <button
            className={`${style.btn} ${style.btnPrimary}`}
            onClick={handleUpdate}
          >
            Save Changes
          </button>
          <button
            className={`${style.btn} ${style.btnSecondary}`}
            onClick={handleResetPassword}
          >
            Reset Password
          </button>
          <button
            className={`${style.btn} ${style.btnDanger}`}
            onClick={handleDeleteAccount}
          >
            Delete Account
          </button>
        </div>
      </div>
    </div>
  );
};

export default Settings;
