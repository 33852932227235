import { useEffect, useRef, useState } from "react";
import styles from "../css/clPreviewA4.module.css";

const CLPreviewA4 = ({ initialData }) => {
  const [coverLetterData, setCoverLetterData] = useState(initialData);
  console.log(initialData);
  const hasAddress =
    (coverLetterData.housenumber?.trim() || "") !== "" ||
    (coverLetterData.street?.trim() || "") !== "" ||
    (coverLetterData.postcode?.trim() || "") !== "" ||
    (coverLetterData.city?.trim() || "") !== "";

  const hasEmailAndPhone =
    (coverLetterData.email?.trim() || "") !== "" &&
    (coverLetterData.phoneNumber?.trim() || "") !== "";

  const hasEmailOrPhone =
    (coverLetterData.email?.trim() || "") !== "" ||
    (coverLetterData.phoneNumber?.trim() || "") !== "";
  const pageRef = useRef(null);
  const [fontSize, setFontSize] = useState(16);

  useEffect(() => {
    setCoverLetterData(initialData);
  }, [initialData]);

  const [height, setHeight] = useState(0);
  useEffect(() => {
    if (pageRef.current) {
      setHeight(pageRef.current.getBoundingClientRect().height);
    }
  }, [initialData]);

  // useEffect(() => {
  //   const handleResize = () => {
  //     if (pageRef.current) {
  //       const pageWidth = pageRef.current.offsetWidth; // Get current page width
  //       if (pageWidth > 0) {
  //         console.log(pageWidth);
  //         const scaleFactor = pageWidth / 210; // Base font size on A4 width in mm
  //         setFontSize(scaleFactor * 5.5); // Adjust font size proportionally
  //       }
  //     }
  //   };

  //   handleResize();
  //   window.addEventListener("resize", handleResize);

  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);
  console.log("-------height ", height);
  console.log("-------height ", height);
  return (
    <div className={styles.pagesContainer}>
      <div className={styles.previewA4} style={{ fontSize: `${fontSize}px` }}>
        <div className={styles.previewContent}>
          {hasAddress && (
            <div className={styles.addressSection}>
              <p>
                {coverLetterData.housenumber} {<br />}
                {coverLetterData.street}
                {<br />}
                {coverLetterData.city} {coverLetterData.postcode}
              </p>
            </div>
          )}

          <p>
            {hasEmailOrPhone && (
              <>
                {coverLetterData.email}
                {hasEmailAndPhone && " | "}
                {coverLetterData.phoneNumber}
                <br />
              </>
            )}
            {/* <br /> */}
            {coverLetterData.dateOnLetter}
            <br />
            {coverLetterData.companyName}
          </p>
          <div>
            <br />
          </div>
          <div
            ref={pageRef}
            className={styles.renderedContent}
            dangerouslySetInnerHTML={{ __html: coverLetterData.content }}
          ></div>
          <p>{coverLetterData.fullName}</p>
        </div>
      </div>
    </div>
  );
};

export default CLPreviewA4;
