import { useEffect, useState } from "react";
import style from "../css/interviewEdit.module.css";
import config from "../common/config";
import axios from "axios";

const InterviewEdit = ({
  applicationId,
  oldNotes,
  oldInterviewDate,
  oldSalaryMin,
  oldSalaryMax,
  onClose,
  onUpdate,
}) => {
  const token = sessionStorage.getItem("token");
  const userName = sessionStorage.getItem("userName");
  const apiUrl = config.apiUrl;

  const isInterviewDateInvalid = oldInterviewDate === "0001-01-01T00:00:00";
  const [interviewDate, setInterviewDate] = useState("");
  useEffect(() => {
    if (!isInterviewDateInvalid) {
      const formattedDate = oldInterviewDate.split("T")[0];
      setInterviewDate(formattedDate);
    }
  }, [oldInterviewDate, isInterviewDateInvalid]);

  const [notes, setNotes] = useState(oldNotes);
  const [salaryLowerLimit, setSalaryLowerLimit] = useState(oldSalaryMin);
  const [salaryUpperLimit, setSalaryUpperLimit] = useState(oldSalaryMax);

  const handleSave = async () => {
    try {
      const response = await axios.put(
        `${apiUrl}/api/v1/applications/updateApplication`,
        {
          applicationId,
          userName,
          interviewDate,
          notes,
          salaryLowerLimit,
          salaryUpperLimit,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        //alert("Interview details saved successfully!");
        onUpdate(response.data);
        onClose(); // Close the popup after saving
        console.log("response dto", response.data);
      }
    } catch (error) {
      console.error("Error saving interview details:", error);
      alert("Failed to save interview details. Please try again.");
    }
  };

  //debug
  console.log("interviewEdit -- app id", salaryLowerLimit);

  return (
    <div className={style.interviewedEditPopup}>
      <h2 className={style.interviewedEditPopupTitle}>Interview Details</h2>
      <p className={style.interviewedEditPopupDescription}>
        Update your salary range and add notes for the interview.
      </p>
      <div className={style.formGroup}>
        <label htmlFor="interviewDate" className={style.label}>
          Interview Date
        </label>
        <input
          type="date"
          id="interviewDate"
          className={style.input}
          value={interviewDate}
          onChange={(e) => setInterviewDate(e.target.value)}
        />
      </div>
      <div className={style.formGroup}>
        <label htmlFor="salaryRange" className={style.label}>
          Salary Range (K)
        </label>
        <div className={style.salaryRangeContainer}>
          <input
            type="number"
            id="salaryLowerLimit"
            className={style.input}
            placeholder="Min"
            value={salaryLowerLimit}
            onChange={(e) => setSalaryLowerLimit(e.target.value)}
          />
          <span className={style.rangeSeparator}>-</span>
          <input
            type="number"
            id="salaryUpperLimit"
            className={style.input}
            placeholder="Max"
            value={salaryUpperLimit}
            onChange={(e) => setSalaryUpperLimit(e.target.value)}
          />
        </div>
      </div>
      <div className={style.formGroup}>
        <label htmlFor="notes" className={style.label}>
          Notes
        </label>
        <textarea
          id="notes"
          className={style.textarea}
          placeholder="Add any important notes..."
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
      </div>
      <div className={style.actions}>
        <button onClick={onClose} className={style.cancelButton}>
          Cancel
        </button>
        <button onClick={handleSave} className={style.saveButton}>
          Save
        </button>
      </div>
    </div>
  );
};

export default InterviewEdit;
