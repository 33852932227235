import React, { useState } from "react";
import style from "../css/offerEdit.module.css";
import axios from "axios";
import config from "../common/config";

const OfferEdit = ({
  applicationId,
  oldNotes,
  oldSalaryMin,
  oldSalaryMax,
  onClose,
  onUpdate,
}) => {
  const userName = sessionStorage.getItem("userName");
  const token = sessionStorage.getItem("token");
  const apiUrl = config.apiUrl;

  const [salaryLowerLimit, setSalaryLowerLimit] = useState(oldSalaryMin);
  const [salaryUpperLimit, setSalaryUpperLimit] = useState(oldSalaryMax);
  const [notes, setNotes] = useState(oldNotes);

  const handleSave = async () => {
    try {
      const response = await axios.put(
        `${apiUrl}/api/v1/applications/updateApplication`,
        {
          applicationId,
          userName,
          notes,
          salaryLowerLimit,
          salaryUpperLimit,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        //alert("Interview details saved successfully!");
        onUpdate(response.data);
        onClose(); // Close the popup after saving
        console.log("response dto", response.data);
      }
    } catch (error) {
      console.error("Error saving interview details:", error);
      alert("Failed to save interview details. Please try again.");
    }
  };

  return (
    <div className={style.offerEditPopup}>
      <h2 className={style.offerEditPopupTitle}>Offer Details</h2>
      <p className={style.offerEditPopupDescription}>
        Update your offered salary and add any relevant notes.
      </p>

      <div className={style.formGroup}>
        <label htmlFor="salary" className={style.label}>
          Salary (K)
        </label>
        <input
          type="number"
          id="salary"
          className={style.input}
          placeholder="Enter Salary"
          value={salaryLowerLimit}
          onChange={(e) => setSalaryLowerLimit(e.target.value)}
        />
      </div>
      <div className={style.formGroup}>
        <label htmlFor="notes" className={style.label}>
          Notes
        </label>
        <textarea
          id="notes"
          className={style.textarea}
          placeholder="Add any important notes..."
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
      </div>
      <div className={style.actions}>
        <button onClick={onClose} className={style.cancelButton}>
          Cancel
        </button>
        <button onClick={handleSave} className={style.saveButton}>
          Save
        </button>
      </div>
    </div>
  );
};

export default OfferEdit;
