import React, { useEffect, useState } from "react";
import style from "../css/statusDropdown.module.css";

function StatusDropdown({ currentApplication, currentStatus, onChange }) {
  const [status, setStatus] = useState(currentStatus);

  useEffect(() => {
    if (currentStatus !== status) {
      setStatus(currentStatus);
    }
  }, [currentStatus]);

  const handleChange = (e) => {
    setStatus(e.target.value);
    currentApplication.status = e.target.value;
    onChange(e.target.value); // Pass the new status to parent component if needed
  };
  //console.log("drop down status", currentStatus);
  // Function to determine if an option should be disabled
  const isOptionDisabled = (optionValue) => {
    const statusOrder = [
      "wishListed",
      "applied",
      "interview",
      "offer",
      "declined",
      "archived",
    ];
    const currentIndex = statusOrder.indexOf(status);
    const optionIndex = statusOrder.indexOf(optionValue);
    return optionIndex < currentIndex; // Disable options earlier than the current status
  };

  return (
    <select
      className={`${style.statusDropdown} ${style[status]}`}
      value={status}
      onChange={handleChange}
    >
      <option value="wishListed" disabled={isOptionDisabled("wishListed")}>
        WishListed
      </option>
      <option value="applied" disabled={isOptionDisabled("applied")}>
        Applied
      </option>
      <option value="interview" disabled={isOptionDisabled("interview")}>
        Interview
      </option>
      <option value="offer" disabled={isOptionDisabled("offer")}>
        Offer
      </option>
      <option value="declined" disabled={isOptionDisabled("declined")}>
        Declined
      </option>
      <option value="archived" disabled={isOptionDisabled("archived")}>
        Archived
      </option>
    </select>
  );
}

export default StatusDropdown;
