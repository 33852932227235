import React, { useState } from "react";
import style from "../css/navBar.module.css"; // Import the CSS module
import logo from "../icons/target-goals-icon.png";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const nav = useNavigate();
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLogout = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("userName");
    sessionStorage.removeItem("isWelcomeComplete");
    nav("/");
  };

  return (
    <nav className={style.navbar}>
      <div className={style.logoContainer} onClick={toggleMenu}>
        <img
          src={logo} // Replace with your logo
          alt="Logo"
          className={style.logo}
        />
      </div>

      {menuOpen && (
        <div className={style.dropdownMenu}>
          <ul>
            <li>
              <a href="/home" className={style.menuItem}>
                Home
              </a>
            </li>
            <li>
              <a href="/application" className={style.menuItem}>
                New Application
              </a>
            </li>
            <li>
              <a href="/analytics" className={style.menuItem}>
                Analytics
              </a>
            </li>
            <li>
              <a href="/settings" className={style.menuItem}>
                Settings
              </a>
            </li>
            <li>
              <a href="/feedback" className={style.menuItem}>
                Feedback
              </a>
            </li>
            <li>
              <a onClick={handleLogout} className={style.menuItem}>
                Log out
              </a>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
