import React, { useState } from "react";
import styles from "../css/appDetailPopup.module.css";

import previewIcon from "../icons/search-magnifying-glass-icon.png";
import downloadIcon from "../icons/download-file-round-icon.png";
import NotePreviewIcon from "../icons/notebook-line-icon.png";
import { toPascalCase, toPascalCaseWithSpace } from "../common/Utility";
import JDPreviewForPopup from "./JDPreviewPageForPopup";
import PreviewPopup from "./PreviewPopup";
import PreviewA4 from "./PreviewA4";
import CLPreviewA4 from "./CLPreviewA4";
import config from "../common/config";
import axios from "axios";
import { Document, Page } from "react-pdf";
import Popup from "../common/Popup";
import PdfPreview from "../common/PdfPreview";
import InterviewEdit from "./InterviewEdit";
import OfferEdit from "./OfferEdit";

const AppDetailPopup = ({
  applicationId,
  jobTitle,
  company,
  location,
  submittedDate,
  interviewDate,
  status,
  description,
  resumeName,
  coverLetter,
  notes,
  salaryMin,
  salaryMax,
  onUpdate,
}) => {
  const [isJDPreviewOpen, setJDPreviewOpen] = useState(false);
  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [isCLPreviewOpen, setIsCLPreviewOpen] = useState(false);
  const [isResumePreviewOpen, setisResumePreviewOpen] = useState(false);
  const [isInterviewEditOpen, setIsInterviewEditOpen] = useState(false);
  const [isOfferEditOpen, setIsOfferEditOpen] = useState(false);
  const [isNotesPopupOpen, setIsNotesPopupOpen] = useState();
  const apiUrl = config.apiUrl;
  const userName = sessionStorage.getItem("userName");
  const token = sessionStorage.getItem("token");

  const isInterviewDateInvalid = interviewDate === "0001-01-01T00:00:00";

  const handleStatusClick = () => {
    if (status === "interview") {
      setIsInterviewEditOpen(true);
    } else if (status === "offer") {
      setIsOfferEditOpen(true);
    }
  };

  const handleJDPreviewClick = () => {
    setJDPreviewOpen(true);
  };
  const handleJDClosePreview = () => setJDPreviewOpen(false);
  const handleJDDownload = () => {
    const blob = new Blob([description], { type: "text/html" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "JobDescription.html";
    link.click();
  };

  //preview pdf file
  const [pdfFile, setPdfFile] = useState(null); // Store the file object
  const [numPages, setNumPages] = useState(null); // Total number of

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages); // Update the total number of pages
  };

  const handleResumePreviewClick = () => {
    console.log("resume preview click");
    setisResumePreviewOpen(true);
  };

  const handleFileDownloadClick = async (fileName) => {
    console.log("--------");
    try {
      const response = await axios.get(
        `${apiUrl}/api/v1/common/download/${userName}/${fileName}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
          responseType: "blob", // Set response type to 'blob' for file download
        }
      );

      // Create a link element to download the file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", resumeName); // Set the downloaded file name
      document.body.appendChild(link);
      link.click();

      // Clean up
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
      if (error.response) {
        console.error("Server responded with:", error.response.data);
      }
    }
  };

  const handleCLPreviewClick = () => {
    if (coverLetter.fileName) {
      setIsCLPreviewOpen(true);
    } else {
      setPreviewOpen(true);
    }
  };
  const handleCLClosePreview = () => setPreviewOpen(false);
  const handleCLDownload = () => {};

  //debug
  console.log("is jd previewOpen", isJDPreviewOpen);
  console.log("---------", status);

  return (
    <div className={styles.popup}>
      {isJDPreviewOpen && (
        <JDPreviewForPopup
          content={description}
          onClose={handleJDClosePreview}
          onDownload={handleJDDownload}
        />
      )}
      {isPreviewOpen && (
        <PreviewPopup
          content={<CLPreviewA4 initialData={coverLetter} />}
          onClose={handleCLClosePreview}
          onDownload={handleCLDownload}
        />
      )}
      <Popup
        isOpen={isResumePreviewOpen}
        onClose={() => {
          setisResumePreviewOpen(false);
        }}
        children={<PdfPreview fileName={resumeName} />}
      />
      <div className={styles.header}>
        <h2 className={styles.jobTitle}>
          <span className={styles.title}>
            {toPascalCaseWithSpace(jobTitle)}
          </span>
          <span className={styles.company}>
            {" "}
            at {toPascalCaseWithSpace(company)} ({location})
          </span>
        </h2>
        <div className={styles.details}>
          <span className={styles.date}>
            Submitted on: {new Date(submittedDate).toLocaleDateString()}
          </span>
          {!isInterviewDateInvalid && (
            <span className={styles.date}>
              Interview on: {new Date(interviewDate).toLocaleDateString()}
            </span>
          )}

          <span
            className={`${styles.status} ${styles[status.toLowerCase()]}`}
            onClick={handleStatusClick}
          >
            {status}
          </span>
        </div>
        <div>
          <img
            alt="notes icon"
            src={NotePreviewIcon}
            onClick={() => {
              setIsNotesPopupOpen(true);
            }}
            className={` ${styles.notesIcon}`}
          />
        </div>
      </div>

      <div className={styles.cardsContainer}>
        <JDCard
          title="Job Description"
          content={description}
          onPreviewClick={handleJDPreviewClick}
        />
        {resumeName && (
          <Card
            title="Job Resume"
            content={resumeName}
            onPreviewClick={handleResumePreviewClick}
            onDownloadClick={() => {
              handleFileDownloadClick(resumeName);
            }}
          />
        )}
        {coverLetter && (
          <Card
            title="Cover Letter"
            content={coverLetter.fileName}
            onPreviewClick={handleCLPreviewClick}
            onDownloadClick={() => {
              handleFileDownloadClick(coverLetter.fileName);
            }}
          />
        )}
        {coverLetter && (
          <Popup
            isOpen={isCLPreviewOpen}
            children={<PdfPreview fileName={coverLetter.fileName} />}
            onClose={() => {
              setIsCLPreviewOpen(false);
            }}
          />
        )}
      </div>
      <Popup
        children={
          <InterviewEdit
            applicationId={applicationId}
            oldNotes={notes}
            oldInterviewDate={interviewDate}
            oldSalaryMin={salaryMin}
            oldSalaryMax={salaryMax}
            onClose={() => {
              setIsInterviewEditOpen(false);
            }}
            onUpdate={onUpdate}
          />
        }
        isOpen={isInterviewEditOpen}
        onClose={() => {
          setIsInterviewEditOpen(false);
        }}
      />
      <Popup
        children={
          <OfferEdit
            applicationId={applicationId}
            oldNotes={notes}
            oldSalaryMin={salaryMin}
            oldSalaryMax={salaryMax}
            onClose={() => {
              setIsOfferEditOpen(false);
            }}
            onUpdate={onUpdate}
          />
        }
        isOpen={isOfferEditOpen}
        onClose={() => {
          setIsOfferEditOpen(false);
        }}
      />
      <Popup
        children={
          <div className={styles.notesContainer}>
            <h2>Notes</h2>
            <span>{notes}</span>
          </div>
        }
        isOpen={isNotesPopupOpen}
        onClose={() => {
          setIsNotesPopupOpen(false);
        }}
      />
    </div>
  );
};

const Card = ({ title, content, onPreviewClick, onDownloadClick }) => (
  <div className={styles.card}>
    <h3>{title}</h3>
    <p className={styles.cardContent}>{content}</p>
    <div className={styles.icons}>
      <img
        src={previewIcon}
        alt="Preview"
        className={styles.icon}
        onClick={onPreviewClick}
      />
      <img
        src={downloadIcon}
        alt="Download"
        className={styles.icon}
        onClick={onDownloadClick}
      />
    </div>
  </div>
);

const JDCard = ({ title, content, onPreviewClick }) => (
  <div className={styles.card}>
    <h3>{title}</h3>
    <div
      className={styles.JDcardContent}
      dangerouslySetInnerHTML={{ __html: content }}
    ></div>
    <div className={styles.icons}>
      <img
        src={previewIcon}
        alt="Preview"
        className={styles.icon}
        onClick={onPreviewClick}
      />
      <img src={downloadIcon} alt="Download" className={styles.icon} />
    </div>
  </div>
);

export default AppDetailPopup;
