import React from "react";
import style from "../css/aboutUs.module.css";
import internetGuyIcon from "../icons/computer-internet-man-icon.png";

const AboutUs = () => {
  return (
    <div className={style.aboutUsContainer}>
      <section className={style.heroSection}>
        <div className={style.heroContent}>
          <h1 className={style.heroTitle}>About Us</h1>
          <p className={style.heroSubtitle}>
            At Eightal, our mission is to help you land a plum job whiling
            making the jouney as simple as possible.
          </p>
        </div>
      </section>

      <section className={style.teamSection}>
        <h2 className={style.sectionTitle}>Meet Our Team</h2>
        <div className={style.teamGrid}>
          <div className={style.teamMember}>
            <img
              src={internetGuyIcon}
              alt="Team Member 1"
              className={style.teamImage}
            />
            <h3>Ron</h3>
            <p>Founder & CEO</p>
          </div>
          <div className={style.teamMember}>
            <img
              src={internetGuyIcon}
              alt="Team Member 2"
              className={style.teamImage}
            />
            <h3>Ron</h3>
            <p>CTO</p>
          </div>
          <div className={style.teamMember}>
            <img
              src={internetGuyIcon}
              alt="Team Member 3"
              className={style.teamImage}
            />
            <h3>Ron</h3>
            <p>Chief Designer</p>
          </div>
        </div>
      </section>

      <section className={style.visionSection}>
        <h2 className={style.sectionTitle}>Our Vision</h2>
        <p className={style.visionText}>
          We believe in the power of innovation and creativity. We strive to
          make the world a better place by providing cutting-edge solutions and
          helping people realize their potential.
        </p>
      </section>

      <section className={style.contactSection}>
        <h2 className={style.sectionTitle}>Get in Touch</h2>
        <p className={style.contactText}>
          Have questions or want to work with us? Reach out to us at{" "}
          <a href="mailto:info@example.com">info@example.com1</a>.
        </p>
      </section>
    </div>
  );
};

export default AboutUs;
