import React, { useState } from "react";
import styles from "../css/coverLetterEditor.module.css";
import Navbar from "../common/NavBar";
import EditorNavbar from "./EditorNavbar";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import CLPreviewA4 from "./CLPreviewA4";

const CoverLetterEditor = ({ initialData, onSaveClick, onCancelClick }) => {
  const nav = useNavigate();
  const [coverLetterData, setCoverLetterData] = useState(initialData);
  const [showPreview, setShowPreview] = useState(false); // For responsive behavior

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCoverLetterData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleCancelClick = () => {
    onCancelClick();
    //nav("/");
  };

  const handleSaveClick = () => {
    onSaveClick(coverLetterData);
  };
  const handleNavbarEditorClick = () => {};

  const handleReactQuillChange = (value) => {
    setCoverLetterData((prevData) => ({
      ...prevData,
      ["content"]: value,
    }));
  };

  console.log("refresh page...");
  console.log(coverLetterData);

  return (
    <div className={styles.container}>
      {/* Responsive Toggle Button for Small Screens */}
      <EditorNavbar
        showPreview={showPreview}
        onCancelClick={handleCancelClick}
        onSaveClick={handleSaveClick}
        onEditorClick={() => setShowPreview(!showPreview)}
        onPreviewClick={() => setShowPreview(!showPreview)}
      />
      <div
        className={`${styles.editorSection} ${
          showPreview ? styles.hideOnMobile : ""
        }`}
      >
        <form className={styles.coverLetterForm}>
          <div className={styles.row}>
            <div className={styles.inputGroup}>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="fullName"
                value={coverLetterData.fullName}
                onChange={handleInputChange}
                placeholder="Your Full Name"
              />
            </div>
            <div className={styles.inputGroup}>
              <label htmlFor="phone">Phone Number</label>
              <input
                type="tel"
                id="phone"
                name="phoneNumber"
                value={coverLetterData.phoneNumber}
                onChange={handleInputChange}
                placeholder="Optional"
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.inputGroup}>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={coverLetterData.email}
                onChange={handleInputChange}
                placeholder="Optional"
              />
            </div>
            <div className={styles.inputGroup}>
              <label htmlFor="date">Date</label>
              <input
                type="date"
                id="date"
                name="dateOnLetter"
                value={coverLetterData.dateOnLetter}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <label htmlFor="address">Address</label>
          <div className={styles.addressGroup}>
            <input
              type="text"
              id="housenumber"
              name="housenumber"
              value={coverLetterData.housenumber}
              onChange={handleInputChange}
              placeholder="House Number"
            />
            <input
              type="text"
              id="street"
              name="street"
              value={coverLetterData.street}
              onChange={handleInputChange}
              placeholder="Street Address"
            />

            <input
              type="text"
              id="city"
              name="city"
              value={coverLetterData.city}
              onChange={handleInputChange}
              placeholder="City"
            />
            <input
              type="text"
              id="postcode"
              name="postcode"
              value={coverLetterData.postcode}
              onChange={handleInputChange}
              placeholder="Postcode"
            />
          </div>

          <label htmlFor="companyName">Company Name</label>
          <input
            type="text"
            id="companyName"
            name="companyName"
            value={coverLetterData.companyName}
            onChange={handleInputChange}
            placeholder="Company Name"
          />

          <label htmlFor="coverLetter">Cover Letter</label>
        </form>
        <div>
          <ReactQuill
            theme="snow"
            className={styles.textEditor}
            onChange={handleReactQuillChange}
            value={coverLetterData.content}
          />
        </div>
      </div>

      <div
        className={`${styles.previewSection} ${
          !showPreview ? styles.hideOnMobile : ""
        }`}
      >
        <CLPreviewA4 initialData={coverLetterData} />
      </div>
    </div>
  );
};

export default CoverLetterEditor;
