import React, { useState } from "react";
import axios from "axios";
import styles from "../css/fileUpload.module.css";

const FileUpload = ({ onSave }) => {
  const [files, setFiles] = useState([]);
  const [file, setFile] = useState(null);
  const [totalSize, setTotalSize] = useState(0);
  const MAX_TOTAL_SIZE = 5 * 1024 * 1024; // 5 MB
  const MAX_FILE_SIZE = 3 * 1024 * 1024; // 3 MB

  //handle multiple file upload
  //   const handleFileChange = (event) => {
  //     const selectedFiles = Array.from(event.target.files);
  //     const newFiles = [];
  //     let newSize = totalSize;

  //     for (const file of selectedFiles) {
  //       if (file.size > MAX_FILE_SIZE) {
  //         alert(`File "${file.name}" exceeds the size limit of 2MB.`);
  //         continue;
  //       }
  //       if (newSize + file.size > MAX_TOTAL_SIZE) {
  //         alert(
  //           "Uploading these files will exceed the total size limit of 10MB."
  //         );
  //         break;
  //       }
  //       newFiles.push(file);
  //       newSize += file.size;
  //     }

  //     setFiles([...files, ...newFiles]);
  //     setTotalSize(newSize);
  //   };
  //   const handleUpload = async () => {
  //     const formData = new FormData();
  //     files.forEach((file) => formData.append("files", file));

  //     try {
  //       const response = await axios.post("/api/upload", formData, {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       });
  //       alert("Files uploaded successfully!");
  //     } catch (error) {
  //       console.error("Error uploading files", error);
  //       alert("Upload failed.");
  //     }
  //   };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      if (selectedFile.size > MAX_FILE_SIZE) {
        alert(`File "${selectedFile.name}" exceeds the size limit of 3MB.`);
        return;
      }
      setFile(selectedFile);
      setTotalSize(selectedFile.size);
    }
  };

  const handleSave = () => {
    if (file) {
      onSave(file);
    } else {
      alert("No file selected!");
    }
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>File Upload</h2>
      <input
        type="file"
        multiple
        accept=".pdf"
        onChange={handleFileChange}
        className={styles.fileInput}
      />
      <p className={styles.totalSize}>
        Format: PDF | Size Limit: 3M | Total size:{" "}
        {(totalSize / (1024 * 1024)).toFixed(2)} MB
      </p>
      <button
        onClick={handleSave}
        disabled={file === null}
        className={styles.uploadButton}
      >
        Save
      </button>
    </div>
  );
};

export default FileUpload;
