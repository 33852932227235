import React from "react";
import style from "../css/alertPopup.module.css";

const AlertPopup = ({ message, onClose }) => {
  return (
    <div className={style.popupOverlay}>
      <div className={style.popupBox}>
        <h2>Alert</h2>
        <p>{message}</p>
        <button className={style.btnClose} onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default AlertPopup;
