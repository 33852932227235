import React from "react";
import styles from "../css/previewForPopup.module.css";

const PreviewPopup = ({ content, onClose, onDownload }) => (
  <div className={styles.overlay}>
    <div className={styles.content}>{content}</div>
    <div className={styles.buttonsContainer}>
      <button onClick={onClose} className={styles.closeButton}>
        Close
      </button>
      <button onClick={onDownload} className={styles.downloadButton}>
        Download
      </button>
    </div>
  </div>
);

export default PreviewPopup;
