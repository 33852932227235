import axios from "axios";
import { useEffect, useState } from "react";
import style from "../css/login.module.css";
import logo from "../icons/goal-icon.png";
import googleIcon from "../icons/google-color-icon.png";
import linkedInIcon from "../icons/linkedin-app-icon.png";
import config from "./config";
import { useNavigate } from "react-router-dom";
import { clearSessionStorageInfo } from "./Utility";
import { jwtDecode } from "jwt-decode";

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const apiUrl = config.apiUrl;
  const token = sessionStorage.getItem("token");
  const nav = useNavigate();
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    console.log("login page loading");
    if (token) {
      const decodedToken = jwtDecode(token); // Decode the token to get expiry date
      const currentTime = Date.now() / 1000; // Convert to seconds

      // Check if token is expired
      if (decodedToken.exp < currentTime) {
        // Token is expired, remove it
        console.log("token expired");
        sessionStorage.removeItem("token");
      } else {
        // Token is valid, user is authenticated
        nav("/home");
      }
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    clearSessionStorageInfo();
    // Post request to backend API
    axios
      .post(`${apiUrl}/api/v1/authentication/authenticate`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("User logged in successfully:", response.data);
        const data = response.data;
        sessionStorage.setItem("token", data.token);
        sessionStorage.setItem("userName", data.userName);
        sessionStorage.setItem("isWelcomeComplete", data.isWelcomeComplete);
        sessionStorage.setItem("planId", data.planId);
        console.log(data.isWelcomeComplete);
        if (data.isWelcomeComplete) {
          nav("/home");
        } else {
          nav("/welcome");
        }
      })
      .catch((error) => {
        console.error("There was an error logging in!", error.response.data);
        clearSessionStorageInfo();
        if (error.response) {
          setError(error.response.data);
        } else {
          setError("Server error");
        }
        // setError("Failed to sign up. Please try again.");
      });
  };

  return (
    <div>
      <nav className={style.navbar}>
        <div className={style.navContainer}>
          <div className={style.navLeft}>
            <a href="/">
              <img className={style.logo} alt="logo" src={logo} />
            </a>
          </div>
          <div className={style.navLinks}>
            <a href="/signup" className={style.signUpLink}>
              Sign Up
            </a>
          </div>
        </div>
      </nav>

      <div className={style.signupRoot}>
        <div>
          <h1 className={style.signupTitle}>Log In</h1>
        </div>

        {error && <div className={style.error}>{error}</div>}
        <form onSubmit={handleSubmit} className={style.signupForm}>
          <div>
            <label htmlFor="email">Email address</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label htmlFor="password">Password</label>
            <div className={style.passwordContainer}>
              <input
                type={showPassword ? "text" : "password"}
                className={style.passwordInput}
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
              <button
                type="button"
                className={style.showPasswordBtn}
                onClick={togglePasswordVisibility}
              >
                {showPassword ? "Hide" : "Show"}
              </button>
            </div>
          </div>
          {/* todo: modifeid checked and formdata */}

          <button type="submit" className={style.btn}>
            Log in
          </button>
          <div className={style.forgotPassword}>Forogt your password?</div>
          <div className={style.divider}>
            <span className={style.dividerText}>or</span>
          </div>
          <div className={style.socialSignupContainer}>
            <div className={style.socialButton}>
              <img className={style.socialIcon} src={googleIcon} />
              Log in with Google
            </div>

            <div className={style.socialButton}>
              <img className={style.socialIcon} src={linkedInIcon} />
              Log in with LinkedIn
            </div>
          </div>
          <div className={style.termsSection}>
            {/* <input
            type="checkbox"
            id="terms"
            name="terms"
            checked={formData.terms}
            onChange={handleChange}
            required
          /> */}
            <label htmlFor="terms" className={style.termsLabel}>
              By siging in to an account, you agree to our{" "}
              <a href="/terms" target="_blank">
                Terms of Service
              </a>{" "}
              and{" "}
              <a href="/privacy" target="_blank">
                Privacy Policy
              </a>
              .
            </label>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
