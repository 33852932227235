import style from "../css/homePublic.module.css";
import logo from "../icons/goal-icon.png";
import Signup from "./Signup";
import step1Pic from "../images/scott-graham-5fNmWej4tAA-unsplash.jpg";
import shakeHands1 from "../images/shake_hands1.jpg";
import signing from "../images/signing.jpg";
import chart from "../images/chart1.jpg";
import Plan from "../tracking/Plan";
import { Element, Link } from "react-scroll";
import { useState } from "react";
import cancelMenuIcon from "../icons/close-button-icon.png";
import menuIcon from "../icons/more-options-ellipsis-icon.png";

const HomePublic = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      <nav className={style.navbar}>
        <div className={style.navContainer}>
          {/* Logo */}
          <a href="/" className={style.logo}>
            <img src={logo} alt="Company Logo" className={style.iconImage} />
            <span className={style.logoText}>Eightal</span>
          </a>

          {/* Mobile Menu Icon */}
          <div className={style.menuIcon} onClick={toggleMenu}>
            <img
              src={isOpen ? cancelMenuIcon : menuIcon}
              alt="Menu Toggle"
              className={style.iconImage}
            />
          </div>

          {/* Links */}
          <div className={`${style.navLinks} ${isOpen ? style.active : ""}`}>
            <a href="/login" className={style.navLink} onClick={toggleMenu}>
              Start Tracking
            </a>
            <Link
              to="pricing"
              smooth={true}
              duration={500}
              className={style.navLink}
              onClick={toggleMenu}
            >
              Plans
            </Link>
            <a href="/aboutus" className={style.navLink} onClick={toggleMenu}>
              About Us
            </a>
            <div className={style.btnGroupOnMobile}>
              <a
                href="/signup"
                className={style.signupBtn}
                onClick={toggleMenu}
              >
                Sign Up
              </a>
              <a href="/login" className={style.loginBtn} onClick={toggleMenu}>
                Log In
              </a>
            </div>
          </div>
        </div>
      </nav>
      <section className={style.welcome}>
        <h1 className={style.welcomeWords}>
          Are you ready for a new adventure?
        </h1>
      </section>
      <section className={style.productSec}>
        <p className={style.productIntro}>
          Ever wondered why job searching cannot be easier and funnier? Let me
          show you how Eightal can help you.
        </p>
      </section>

      {/* steps to use products */}
      <section className={style.stepsSection}>
        {/* Step 1: Text on the left, Image on the right */}
        <div className={style.step}>
          <div className={style.stepText}>
            <h2>Step 1: Find a job opening</h2>
            <p>
              Start with a job opening you are interested in. You can find them
              on the platforms like Indeed, Glassdoor or LinkedIn.
            </p>
          </div>
          <div className={style.stepImage}>
            <img src={step1Pic} alt="Step 1" />
          </div>
        </div>

        {/* Step 2: Image on the left, Text on the right */}
        <div className={`${style.step} `}>
          <div className={style.stepImage}>
            <img src={shakeHands1} alt="Step 2" />
          </div>
          <div className={style.stepText}>
            <h2>Step 2: Set up your account</h2>
            <p>
              Register your account and personalize your resume and cover letter
              with a few simple steps.
            </p>
          </div>
        </div>

        {/* Step 3: Text on the left, Image on the right */}
        <div className={style.step}>
          <div className={style.stepText}>
            <h2>Step 3: Secure an offer</h2>
            <p>
              we will guide you through the job searching jouney and help you
              land a plum offer.
            </p>
          </div>
          <div className={style.stepImage}>
            <img src={signing} alt="Step 3" />
          </div>
        </div>
      </section>
      <div className={style.startButtonContainer}>
        <a href="/login" className={style.startButton}>
          Get Started 🚀
        </a>
      </div>

      {/* testimonial part */}
      <section className={style.testimonialSection}>
        <h2 className={style.testimonialTitle}>What Our Users Are Saying</h2>

        <div className={style.testimonialCards}>
          <div className={style.testimonialCard}>
            <p className={style.comment}>
              "This product has completely transformed my workflow. Highly
              recommend!"
            </p>
            <p className={style.user}>- John Doe</p>
          </div>

          <div className={style.testimonialCard}>
            <p className={style.comment}>
              "Amazing customer service and very easy to use. I'm very
              satisfied."
            </p>
            <p className={style.user}>- Jane Smith</p>
          </div>

          <div className={style.testimonialCard}>
            <p className={style.comment}>
              "The best tool I've ever used. It saves me so much time every
              day."
            </p>
            <p className={style.user}>- Mark Johnson</p>
          </div>
        </div>
      </section>

      <Element name="pricing">
        <Plan />
      </Element>
    </div>
  );
};

export default HomePublic;
