import React, { useState } from "react";
import styles from "../css/resumeEditor.module.css";

function ResumeEditor() {
  const [mode, setMode] = useState("editor"); // Track if we're in 'editor' or 'designer' mode
  const [resumeData, setResumeData] = useState({
    name: "",
    email: "",
    phone: "",
    experience: "",
    education: "",
    skills: "",
  });

  const [sectionsOrder, setSectionsOrder] = useState([
    "experience",
    "education",
    "skills",
  ]); // Order of the sections

  const handleChange = (e) => {
    const { name, value } = e.target;
    setResumeData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const moveSection = (direction, index) => {
    setSectionsOrder((prevOrder) => {
      const newOrder = [...prevOrder];
      const [removed] = newOrder.splice(index, 1);
      const newIndex = direction === "up" ? index - 1 : index + 1;

      newOrder.splice(newIndex, 0, removed);
      return newOrder;
    });
  };

  return (
    <div className={styles.resumeContainer}>
      <div className={styles.editorSection}>
        <div className={styles.header}>
          <button
            className={mode === "editor" ? styles.activeButton : ""}
            onClick={() => setMode("editor")}
          >
            Editor
          </button>
          <button
            className={mode === "designer" ? styles.activeButton : ""}
            onClick={() => setMode("designer")}
          >
            Designer
          </button>
        </div>

        <div className={styles.contentArea}>
          {mode === "editor" ? (
            <>
              <h2>Edit Your Resume</h2>
              <form>
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  onChange={handleChange}
                  value={resumeData.name}
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  onChange={handleChange}
                  value={resumeData.email}
                />
                <input
                  type="text"
                  name="phone"
                  placeholder="Phone"
                  onChange={handleChange}
                  value={resumeData.phone}
                />

                {sectionsOrder.map((section, index) => (
                  <div key={section}>
                    {section === "experience" && (
                      <textarea
                        name="experience"
                        placeholder="Experience"
                        onChange={handleChange}
                        value={resumeData.experience}
                      ></textarea>
                    )}
                    {section === "education" && (
                      <textarea
                        name="education"
                        placeholder="Education"
                        onChange={handleChange}
                        value={resumeData.education}
                      ></textarea>
                    )}
                    {section === "skills" && (
                      <textarea
                        name="skills"
                        placeholder="Skills"
                        onChange={handleChange}
                        value={resumeData.skills}
                      ></textarea>
                    )}
                  </div>
                ))}
              </form>
            </>
          ) : (
            <>
              <h2>Designer Mode: Reorder Sections</h2>
              <ul className={styles.sectionList}>
                {sectionsOrder.map((section, index) => (
                  <li key={section} className={styles.sectionItem}>
                    <span>
                      {section.charAt(0).toUpperCase() + section.slice(1)}
                    </span>
                    <div>
                      <button
                        onClick={() => moveSection("up", index)}
                        disabled={index === 0}
                      >
                        Move Up
                      </button>
                      <button
                        onClick={() => moveSection("down", index)}
                        disabled={index === sectionsOrder.length - 1}
                      >
                        Move Down
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      </div>

      <div className={styles.previewSection}>
        <div className={styles.contentArea}>
          <ResumeTemplate data={resumeData} sectionsOrder={sectionsOrder} />
        </div>
      </div>
    </div>
  );
}

function ResumeTemplate({ data, sectionsOrder }) {
  return (
    <div className={styles.resumePreview}>
      <h2>{data.name}</h2>
      <p>Email: {data.email}</p>
      <p>Phone: {data.phone}</p>

      {sectionsOrder.map((section) => (
        <div key={section}>
          {section === "experience" && (
            <>
              <h3>Experience</h3>
              <p>{data.experience}</p>
            </>
          )}
          {section === "education" && (
            <>
              <h3>Education</h3>
              <p>{data.education}</p>
            </>
          )}
          {section === "skills" && (
            <>
              <h3>Skills</h3>
              <p>{data.skills}</p>
            </>
          )}
        </div>
      ))}
    </div>
  );
}

export default ResumeEditor;
