import { useLocation, useNavigate } from "react-router-dom";
import style from "../css/application.module.css";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import config from "../common/config";
import Navbar from "../common/NavBar";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import powerOn from "../icons/connected-plug-icon.png";
import powerOff from "../icons/disconnect-plug-icon.png";
import cvIcon from "../icons/text-document-dual-tone-icon.png";
import ConfirmPopup from "../common/ConfirmPopup";
import CoverLetterEditor from "./CoverLetterEditor";
import PreviewA4 from "./PreviewA4";
import { setsEqual } from "chart.js/helpers";
import CLPreviewA4 from "./CLPreviewA4";
import PreviewPopup from "./PreviewPopup";
import FileUpload from "../common/FileUpload";
import Popup from "../common/Popup";

const Application = () => {
  const pageRef = useRef(null);
  //job info variable
  const [jobTitle, setJobTitle] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [location, setLocation] = useState("");
  const [salaryRange, setSalaryRange] = useState({ lower: "", upper: "" });
  const [status, setStatus] = useState(0);
  const [isToggled, setIsToggled] = useState(false);
  //job description
  const [JDParagraph, setJDParagraph] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [isKeywordsExtracted, setIsKeywordsExtracted] = useState(false);

  const [coverletter, setCoverLetter] = useState("");
  //job cover letter
  const [isCLUploadPopupOpen, setIsCLUploadPopupOpen] = useState(false);
  const [uploadedCL, setUploadedCL] = useState(null);
  const [coverLetterData, setCoverLetterData] = useState({
    fullName: "",
    phoneNumber: "",
    email: "",
    dateOnLetter: "",
    housenumber: "",
    street: "",
    city: "",
    postcode: "",
    companyName: "",
    content: "",
  });
  const [hasCoverLetter, setHasCoverLetter] = useState(false);

  const handleUploadedCLSave = (file) => {
    setUploadedCL(file);
    setIsCLUploadPopupOpen(false);
  };

  //job resumes

  const [hasResume, setHasResume] = useState(false);
  const [uploadedResume, setUploadedResume] = useState(null);
  const [isResumeUploadPopupOpen, setIsResumeUploadPopupOpen] = useState(false);

  const apiUrl = config.apiUrl;
  const token = sessionStorage.getItem("token");
  const userName = sessionStorage.getItem("userName");
  const planId = sessionStorage.getItem("planId");
  const payload = {
    text: JDParagraph,
  };

  const handleUploadedResumeSave = (file) => {
    setUploadedResume(file);
    setIsResumeUploadPopupOpen(false);
  };

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const nav = useNavigate();
  const query = useQuery();
  const step = query.get("step") || "job-description";

  //funcs
  // const handleParagraphChange = (e) => {
  //   setJDParagraph(e.target.value);
  //   setIsKeywordsExtracted(false);
  // };

  const toggleSwitch = () => {
    console.log("planId", planId);
    if (planId && planId == 1) {
      handleShowAlert();
    }
    setIsToggled(!isToggled);
  };

  const handleSalaryChange = (e) => {
    const { name, value } = e.target;
    setSalaryRange((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic
    console.log({ jobTitle, companyName, location, salaryRange });
  };

  const handleJDParagraphChange = (value) => {
    setJDParagraph(value);
    setIsKeywordsExtracted(false);
  };

  const handleCoverLetterChange = (e) => {
    setCoverLetter(e.target.value);
  };

  const handleCoverLetterSave = (updatedData) => {
    setCoverLetterData(updatedData);
  };

  //cover letter editor
  const handleCoverLetterCancel = () => {
    //console.log("-------cover letter data cancel");
    setCoverLetterData({
      fullName: "",
      phoneNumber: "",
      email: "",
      dateOnLetter: "",
      housenumber: "",
      street: "",
      city: "",
      postcode: "",
      companyName: "",
      content: "",
    });
  };

  const handleWishListClick = () => {
    //setStatus(0);
    submitApplication(0);
  };

  const handleAppliedClick = () => {
    setStatus(1);
    submitApplication(1);
  };

  // summary
  const [isCLPreviewOpen, setIsCLPreviewOpen] = useState(false);
  const [isResumePreviewOpen, setIsResumePreviewOpen] = useState(false);

  const handleCLClosePreview = () => {
    setIsCLPreviewOpen(false);
  };
  const handleCLDownload = () => {};

  const submitApplication = async (newStatus) => {
    const applicationPayload = {
      userName: userName,
      jobTitle: jobTitle,
      companyName: companyName,
      salaryUpperLimit: salaryRange.upper === "" ? null : salaryRange.upper, // Convert empty strings to null
      salaryLowerLimit: salaryRange.lower === "" ? null : salaryRange.lower, // Convert empty strings to null
      location: location,
      coverLetter: {
        fullName: coverLetterData.fullName,
        phoneNumber:
          coverLetterData.phoneNumber === ""
            ? null
            : parseInt(coverLetterData.phoneNumber), // Convert to integer
        email: coverLetterData.email,
        dateOnLetter: coverLetterData.dateOnLetter || null, // Ensure valid or null
        houseNumber: coverLetterData.housenumber || null, // Use correct property name
        street: coverLetterData.street || null,
        city: coverLetterData.city || null,
        postCode: coverLetterData.postcode || null, // Use correct property name
        companyName: coverLetterData.companyName || null,
        content: coverLetterData.content || "", // Ensure it's a string
      },
      jobDescription: JDParagraph,
      status: newStatus,
    };

    // console.log("========payload", applicationPayload);
    // console.log("resume===", uploadedResume);
    // console.log("cl ------ ", uploadedCL);

    try {
      const response = await axios.post(
        `${apiUrl}/api/v1/applications`,
        applicationPayload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        const applicationId = response.data;
        const formData = new FormData();
        formData.append("userName", userName);
        formData.append("applicationId", applicationId);
        formData.append("coverLetter", uploadedCL);
        formData.append("resume", uploadedResume);

        console.log(formData);
        const response2 = await axios.post(
          `${apiUrl}/api/v1/common/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        //await handleUploadCLAndResumeFiles();
      }

      console.log(response.data);
      nav("/home");
    } catch {
      alert("something went wrong, please try again later.");
    }
  };

  const items = [
    { id: 1, label: "Job Description", key: "jobDescription" },
    { id: 2, label: "Job Information", key: "jobInformation" },
    { id: 3, label: "Job Resume", key: "jobResume" },
    { id: 4, label: "Job Cover Letter", key: "jobCoverLetter" },
  ];

  const [overallStatus, setOveralStatus] = useState({
    jobDescription: false,
    jobInformation: false,
    jobResume: false,
    jobCoverLetter: false,
  });
  const [currentIndex, setCurrentIndex] = useState(-1);

  useEffect(() => {
    // Simulate sequential checking with animation
    items.forEach((item, index) => {
      setTimeout(() => {
        setCurrentIndex(index); // Highlight current item
        setOveralStatus((prev) => ({
          ...prev,
          [item.key]: Math.random() > 0.5,
        })); // Random completion status

        // If it's the last item, reset `currentIndex` after showing the status
        if (index === items.length - 1) {
          setTimeout(() => {
            setCurrentIndex(-1);
          }, 1000);
        }
      }, index * 1000); // Delay for each item
    });
  }, []);

  const extractKeywords = async () => {
    if (!JDParagraph.trim()) {
      // If paragraph is empty, don't proceed
      console.log("Paragraph is empty.");
      return;
    }
    try {
      const response = await axios.post(
        `${apiUrl}/api/v1/keyword/extract`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (Array.isArray(response.data.keywords)) {
        setKeywords(response.data.keywords); // Update state with keywords
      } else {
        console.error("Keywords are not in an array:", response.data.keywords);
      }
      console.log(response.data.keywords.$values);
      setKeywords(response.data.keywords.$values); // Assuming the API returns the keywords
      setIsKeywordsExtracted(true);
    } catch (error) {
      console.error("Error extracting keywords:", error);
    }
  };
  const goToNextStep = () => {
    switch (step) {
      case "job-description":
        nav("/application?step=job-info");
        break;
      case "job-info":
        nav("/application?step=resume");
        break;
      case "resume":
        nav("/application?step=cover-letter");
        break;
      case "cover-letter":
        nav("/application?step=summary");
        break;
      case "summary":
        submitApplication();
        break;
      default:
        break;
    }
  };

  const openCoverLetterEditor = () => {
    nav("/application?step=cover-letter-editor");
  };

  const isFormValid = () => {
    const lower = parseFloat(salaryRange.lower);

    const upper = parseFloat(salaryRange.upper);

    const isSalaryValueValid =
      lower <= upper ||
      salaryRange.lower.trim() == "" ||
      salaryRange.upper.trim() == "";

    return (
      jobTitle.trim() !== "" &&
      companyName.trim() !== "" &&
      location.trim() !== "" &&
      // salaryRange.lower.trim() !== "" &&
      // salaryRange.upper.trim() !== "" &&
      isSalaryValueValid
    );
  };

  const [showAlert, setShowAlert] = useState(false);

  const handleShowAlert = () => {
    setShowAlert(true);
  };

  const handleConfirm = () => {
    //nav("/paidplans");
    setIsToggled(false);
    setShowAlert(false);
  };

  const handleCloseAlert = () => {
    setIsToggled(false);
    setShowAlert(false);
  };

  const [selectedResume, setSelectedResume] = useState(null);

  const handleCheckboxChange = (index) => {
    setSelectedResume(index);
  };

  const resumes = [
    { name: "ResumeResumeResumeResumeResume 1" },
    { name: "software engineer resume" },
    { name: "Resume 3" },
    { name: "Resume 3" },
    { name: "Resume 3" },
    { name: "Resume 1" },
    { name: "Resume 2" },
    { name: "Resume 3" },
    { name: "Resume 3" },
    { name: "Resume 3" },
    // Add more resumes as needed
  ];

  const handleUploadCLAndResumeFiles = async (formData) => {
    if (uploadedCL === null && uploadedResume === null) return;

    // const formData = new FormData();
    // formData.append("coverLetter", uploadedCL);
    // formData.append("resume", uploadedResume);

    console.log("formData --", formData);
    try {
      const response = await axios.post(
        `${apiUrl}/api/v1/common/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("send upload file request");
      //alert("Files uploaded successfully!");
    } catch (error) {
      console.error("Error uploading files", error);
      //alert("Upload failed.");
    }
  };

  // Function to render different steps
  const renderStepContent = () => {
    switch (step) {
      case "job-info":
        return (
          <div>
            <Navbar />

            <div className={style.rootBox}>
              <div className={style.resumeBox}>
                <h2>Fill in Job Information</h2>
                <form onSubmit={handleSubmit} className={style.form}>
                  <div className={style.inputGrid}>
                    <div className={style.inputGroup}>
                      <label htmlFor="jobTitle">Job Title:</label>
                      <input
                        type="text"
                        id="jobTitle"
                        value={jobTitle}
                        onChange={(e) => setJobTitle(e.target.value)}
                        placeholder="Enter job title"
                        className={style.inputField}
                      />
                    </div>

                    <div className={style.inputGroup}>
                      <label htmlFor="companyName">Company Name:</label>
                      <input
                        type="text"
                        id="companyName"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        placeholder="Enter company name"
                        className={style.inputField}
                      />
                    </div>

                    <div className={style.inputGroup}>
                      <label htmlFor="location">Location:</label>
                      <input
                        type="text"
                        id="location"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                        placeholder="Enter job location"
                        className={style.inputField}
                      />
                    </div>

                    <div className={style.inputGroup}>
                      <label htmlFor="salaryRange">
                        Annual Salary Range: (K)
                      </label>
                      <div className={style.salaryRangeContainer}>
                        <input
                          type="number"
                          name="lower"
                          value={salaryRange.lower}
                          onChange={handleSalaryChange}
                          placeholder="Lower"
                          className={style.salaryInput}
                          min="0"
                        />
                        <span className={style.hyphen}>-</span>
                        <input
                          type="number"
                          name="upper"
                          value={salaryRange.upper}
                          onChange={handleSalaryChange}
                          placeholder="Upper"
                          className={style.salaryInput}
                          min={salaryRange.lower || 0} // Ensure upper limit is higher than lower limit
                        />
                      </div>
                    </div>
                  </div>

                  {/* <button type="submit" className={style.btnCreate}>
                Submit
              </button> */}
                  <button
                    onClick={goToNextStep}
                    className={style.btnContinue}
                    disabled={!isFormValid()}
                  >
                    Continue
                  </button>
                </form>
              </div>
            </div>
          </div>
        );
      case "job-description":
        return (
          <div>
            <Navbar />
            {showAlert && (
              <ConfirmPopup
                message="This feature will be coming out soon."
                confirmText="Upgrade"
                onConfirm={handleConfirm}
                onClose={handleCloseAlert}
              />
            )}
            <div className={style.switchContainer}>
              <div className={style.switchLabel}>
                {/* Toggle between your icons based on the state */}
                <img
                  className={style.powerIcon}
                  src={isToggled ? powerOn : powerOff}
                />{" "}
                Powered by AI
              </div>
              <label className={style.switch}>
                <input
                  type="checkbox"
                  checked={isToggled}
                  onChange={toggleSwitch}
                />
                <span className={style.slider}></span>
              </label>
            </div>
            <div className={style.rootBox}>
              <div className={style.jdBox}>
                <h2 className={style.title}>
                  Read the job description carefully
                </h2>
                <div className={style.textBoxContainer}>
                  <div>
                    <ReactQuill
                      theme="snow"
                      value={JDParagraph}
                      onChange={handleJDParagraphChange}
                      className={style.textEditor}
                    />
                  </div>
                  <br />
                  <button
                    className={style.extractButton}
                    onClick={extractKeywords}
                  >
                    Extract Keywords
                  </button>

                  {keywords.length > 0 && (
                    <div className={style.keywordsContainer}>
                      <h3 className={style.keywordsTitle}>
                        Extracted Keywords:
                      </h3>
                      <ul className={style.keywordsList}>
                        {keywords.map((keyword, index) => (
                          <li key={index} className={style.keywordItem}>
                            {keyword}
                          </li>
                        ))}
                      </ul>
                      <div>
                        <p>
                          Keep these keywords in mind, you need to try to show
                          them as much as possible in your resume/cover letter.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <button
                  className={style.btnContinue}
                  onClick={goToNextStep}
                  disabled={!JDParagraph.trim()}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        );
      case "resume":
        return (
          <div>
            <Navbar />
            <div className={style.rootBox}>
              <div className={style.resumeBox}>
                <h2>Make/Upload a resume</h2>
                {/* Collection of resumes */}
                {/* Example of a resume item */}
                {/* <div className={style.resumeCollection}>
                  {resumes.map((resume, index) => (
                    <div key={index} className={style.resumeItem}>
                      <input
                        type="checkbox"
                        className={style.resumeCheckbox}
                        id={`resume-${index}`}
                        onChange={() => handleCheckboxChange(index)}
                        checked={selectedResume === index}
                      />
                      <label
                        htmlFor={`resume-${index}`}
                        className={style.childResumeContainer}
                      >
                        <div className={style.resumeIconContainer}>
                          <img className={style.resumeIcon} src={cvIcon} />
                        </div>
                        <div className={style.resumeName}>{resume.name}</div>
                      </label>
                    </div>
                  ))}
                </div> */}
                <Popup
                  children={<FileUpload onSave={handleUploadedResumeSave} />}
                  isOpen={isResumeUploadPopupOpen}
                  onClose={() => {
                    setIsResumeUploadPopupOpen(false);
                  }}
                />
                {uploadedResume && (
                  <p>The File To Upload: {uploadedResume.name}</p>
                )}
                {/* Buttons */}
                <div className={style.clBtnGroup}>
                  <button className={style.btnCreate} disabled={true}>
                    Create
                  </button>
                  {uploadedResume ? (
                    <button
                      className={style.btnCreate}
                      onClick={() => {
                        setUploadedResume(null);
                      }}
                    >
                      Remove
                    </button>
                  ) : (
                    <button
                      className={style.btnCreate}
                      onClick={() => {
                        setIsResumeUploadPopupOpen(true);
                      }}
                    >
                      Upload
                    </button>
                  )}

                  <button className={style.btnContinue} onClick={goToNextStep}>
                    {uploadedResume ? "Continue" : "Skip"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      case "cover-letter":
        return (
          <div>
            <Navbar />
            <div className={style.resumeBox}>
              <h2>Does the job application require a cover letter?</h2>
              <div className={style.textBoxContainer}>
                {/* <textarea
                  value={coverletter}
                  onChange={handleCoverLetterChange}
                  placeholder="Just start writing your cover letter here. We'll help you with all the format and refine it later with our powerful AI tools."
                  rows="18"
                  className={style.textArea}
                ></textarea> */}
                Once you create your cover letter, you will view it here. Try
                using our AI tools to tailor your cover letter.
                {uploadedCL && <p>The File To Upload: {uploadedCL.name}</p>}
                {/* <br /> */}
                {coverLetterData.content && (
                  <CLPreviewA4 initialData={coverLetterData} />
                )}
              </div>
              <Popup
                children={<FileUpload onSave={handleUploadedCLSave} />}
                isOpen={isCLUploadPopupOpen}
                onClose={() => {
                  setIsCLUploadPopupOpen(false);
                }}
              />
              {coverLetterData.content ? (
                <div className={style.clBtnGroup}>
                  <button
                    className={style.btnCreate}
                    onClick={openCoverLetterEditor}
                  >
                    Edit
                  </button>
                  <button className={style.btnContinue} onClick={goToNextStep}>
                    Continue
                  </button>
                </div>
              ) : (
                <div className={style.clBtnGroup}>
                  <button
                    className={style.btnCreate}
                    onClick={openCoverLetterEditor}
                    disabled={uploadedCL !== null}
                  >
                    Create
                  </button>
                  {uploadedCL ? (
                    <button
                      className={style.btnCreate}
                      onClick={() => {
                        setUploadedCL(null);
                      }}
                    >
                      Remove
                    </button>
                  ) : (
                    <button
                      className={style.btnCreate}
                      onClick={() => {
                        setIsCLUploadPopupOpen(true);
                      }}
                    >
                      Upload
                    </button>
                  )}
                  <button className={style.btnContinue} onClick={goToNextStep}>
                    {uploadedCL ? "Continue" : "Skip"}
                  </button>
                </div>
              )}
            </div>
          </div>
        );
      case "cover-letter-editor":
        return (
          <CoverLetterEditor
            initialData={coverLetterData}
            onSaveClick={handleCoverLetterSave}
            onCancelClick={handleCoverLetterCancel}
          />
        );
        break;
      case "summary":
        return (
          <div>
            <Navbar />
            <div className={style.rootBox}>
              {isCLPreviewOpen && (
                <PreviewPopup
                  content={<CLPreviewA4 initialData={coverLetterData} />}
                  onClose={handleCLClosePreview}
                  onDownload={handleCLDownload}
                />
              )}
              <div className={style.resumeBox}>
                <div className={style.previewBox}>
                  {hasResume && (
                    <div className={style.previewItem}>
                      <h2>Resume</h2>

                      <div className={style.icons}>
                        <div className={style.icon}>
                          {/* <span>📄</span> */}
                          <img
                            src={cvIcon}
                            alt="file icon"
                            className={style.fileIcon}
                          />
                          <div className={style.buttons}>
                            <button>Preview</button>
                            <button>Download</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {coverLetterData.content && (
                    <div className={style.previewItem}>
                      <h2>Cover Letter</h2>
                      <div className={style.icons}>
                        <div className={style.icon}>
                          {/* <span>📄</span> */}
                          <img
                            src={cvIcon}
                            alt="file icon"
                            className={style.fileIcon}
                          />
                          <div className={style.buttons}>
                            <button
                              onClick={() => {
                                setIsCLPreviewOpen(true);
                              }}
                            >
                              Preview
                            </button>
                            <button>Download</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <h2>Have you submitted the application?</h2>
                <div className={style.summaryList}>
                  <ul className={style.list}>
                    {items.map((item, index) => (
                      <li
                        key={item.id}
                        className={`${style.listItem} ${
                          currentIndex === index ? style.active : ""
                        }`}
                      >
                        {item.label}
                        {currentIndex > index || currentIndex === -1 ? (
                          overallStatus[item.key] ? (
                            <span className={style.check}>✔</span>
                          ) : (
                            <span className={style.cross}>❌</span>
                          )
                        ) : (
                          <span className={style.loader}></span>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className={style.clBtnGroup}>
                  <button
                    className={`${style.btnWishlistIt}`}
                    onClick={handleWishListClick}
                  >
                    Wishlist
                  </button>
                  <button
                    className={`${style.btnApplied}`}
                    onClick={handleAppliedClick}
                  >
                    Applied
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return <div>{renderStepContent()}</div>;
};

export default Application;
