// utility.js
export const formatDate = (dateString) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const clearSessionStorageInfo = () => {
  //localStorage or sessionStorage?

  sessionStorage.removeItem("token");
  sessionStorage.removeItem("userName");
  sessionStorage.removeItem("isWelcomeComplete", false);
  sessionStorage.removeItem("planId");
};

export function toPascalCaseWithSpace(str) {
  return str
    ? str
        .split(" ") // Split the string by spaces (or you could use another delimiter)
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        ) // Capitalize first letter, lowercase the rest
        .join(" ")
    : "";
}
