import React, { useEffect, useState } from "react";
import { Document, Page } from "react-pdf"; // Import from the react-pdf package
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import config from "./config";
import axios from "axios";

const PdfPreview = ({ fileName }) => {
  //   pdfjs.GlobalWorkerOptions.workerSrc =
  //     "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.7.107/pdf.worker.min.js";
  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    "pdfjs-dist/build/pdf.worker.min.mjs",
    import.meta.url
  ).toString();

  const [pdfFile, setPdfFile] = useState(null); // Store the file object
  const [hasPdfFile, setHasPdfFile] = useState(false);
  const [numPages, setNumPages] = useState(null); // Total number of pages
  const [pageNumber, setPageNumber] = useState(1);

  const userName = sessionStorage.getItem("userName");
  const token = sessionStorage.getItem("token");
  const apiUrl = config.apiUrl;

  useEffect(() => {
    if (fileName) {
      fetchPdfFile(fileName);
    }
  }, [fileName]);

  // Fetch the file from the server
  const fetchPdfFile = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/v1/common/download/${userName}/${fileName}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
          responseType: "blob", // Set response type to 'blob' for file download
        }
      );
      // Create an object URL for the PDF blob
      const pdfBlobUrl = URL.createObjectURL(response.data);
      setPdfFile(pdfBlobUrl);
      setHasPdfFile(true);
    } catch (error) {
      console.error("Error fetching the PDF file:", error);
      alert("Failed to load the PDF file.");
    }
  };

  // Handle file upload
  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile && uploadedFile.type === "application/pdf") {
      setPdfFile(URL.createObjectURL(uploadedFile)); // Create an object URL for the file
    } else {
      alert("Please upload a valid PDF file.");
    }
  };

  // Callback when PDF loads successfully
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages); // Update the total number of pages
  };

  return (
    <div>
      {/* <h2>PDF File Preview</h2> */}
      {/* <input
        type="file"
        accept="application/pdf"
        onChange={handleFileChange}
        style={{ marginBottom: "20px" }}
      /> */}
      {hasPdfFile ? (
        <div>
          <Document
            file={pdfFile}
            onLoadSuccess={onDocumentLoadSuccess}
            loading="Loading PDF..."
          >
            {/* Render all pages */}
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={index} pageNumber={index + 1} />
            ))}
            {/* <p>
              Page {pageNumber} of {numPages}
            </p> */}
          </Document>
        </div>
      ) : (
        <p>No PDF selected for preview</p>
      )}
    </div>
  );
};

export default PdfPreview;
