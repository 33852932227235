import React from "react";
import styles from "../css/checkoutPage.module.css"; // Import CSS module

const PaymentSuccessPage = () => {
  const handleReturn = () => {
    window.location.href = "/dashboard"; // Navigate to the dashboard or any other page
  };

  return (
    <div className={styles.successContainer}>
      <header className={styles.successHeader}>
        <h1>Your Company Logo</h1>
      </header>

      <div className={styles.successBody}>
        <h2>🎉 Payment Successful!</h2>
        <p className={styles.thankYou}>
          Thank you for subscribing to the Professional Plan.
        </p>

        <div className={styles.planSummary}>
          <p>
            <strong>Plan:</strong> Professional Plan
          </p>
          <p>
            <strong>Start Date:</strong> October 14, 2024
          </p>
          <p>
            <strong>Next Payment:</strong> November 14, 2024
          </p>
        </div>

        <p className={styles.nextSteps}>
          You can now access all the premium features in your dashboard.
        </p>

        <button className={styles.returnButton} onClick={handleReturn}>
          Go to Dashboard
        </button>
      </div>
    </div>
  );
};

export default PaymentSuccessPage;
